import {changeSetting} from './settings.actions';

export const SET_MENU_ITEMS = 'SET_MENU_ITEMS';
export const UPDATE_MENU_ITEMS = 'UPDATE_MENU_ITEMS';
export const SET_POP_OVER = 'SET_POP_OVER';

export const MenuItems = menuItems =>  {
    return { type: SET_MENU_ITEMS, menuItems };
} 
export const setMenuItems = menuItems => {
    return dispatch => {
        dispatch(changeSetting("isCollapsed",false))
      dispatch(MenuItems(menuItems));
    };
  };

export const updateMenuItems = menuItems => {
    return { type: UPDATE_MENU_ITEMS, menuItems };
}

export const setUpdatefalse = () => ({ type: 'SET_FALSE' })

export const openInAnotherTab = (value) => ({
    type: 'OPEN_IN_ANOTHER_TAB',
    isOpenedInModal: true
})

export const enableEditForModal = () => ({
    type: 'ENABLE_EDIT'
})

export const setPopoverNewTab = () => ({
    type: 'SET_POP_OVER'
})