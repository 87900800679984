import { API, GET_ACCOUNTS,CHANGE_PASSWORD,GET_CURRENT_USER } from "./../../components/services/api";
import { setLocalStorage,removeLocalStorage,getLocalStorage } from "./../../utilities/utilities";

// to get accounts
export const UserAccounts = ({ cb }) => {
  return dispatch => {
    return API.get(GET_ACCOUNTS, {}, {})
      .then(res => {
        // console.log("_res", res);
        if (cb) cb(res);
        dispatch(accountInformation(res));
      })
      .catch(err => {
        console.log("_error", err);
        return err;
      });
  };
};
// Reset password API
export const resetPassword = () => {
  return dispatch => {
    return API.post(CHANGE_PASSWORD, {}, {})
      .then(res => {
        dispatch(notificationToast({ type: "success", message: "A password reset link has been sent to your email" }));
        dispatch(clearValue());
      })
      .catch(err => {
       console.log("_err",err)
      });
  };
}
// read the current User
export const getCurrentUser =() => {
  return dispatch => {
    return API.get(GET_CURRENT_USER, {}, {})
      .then(res => {
        // if (cb) cb(res);
        dispatch(CurrentUser(res));
      })
      .catch(err => {
        console.log("_error", err);
        return err;
      });
  };
}
export const SET_TOKEN = "SET_TOKEN";
export const SET_PERMISSION = "SET_PERMISSION";
export const SHOW_TOAST = "SHOW_TOAST";
export const DISABLE_TOAST = "DISABLE_TOAST"; 
export const ACCOUNT_INFORMATION="ACCOUNT_INFORMATION";
export const CURRENT_USER = "CURRENT_USER";
export const LOADING_IMAGE = "LOADING_IMAGE";
export const MODAL_TOAST = "MODAL_TOAST"; 
export const DISABLE_MODAL_TOAST ="DISABLE_MODAL_TOAST";
export const TOAST_FLAG = "TOAST_FLAG";
export const SET_USER = "SET_USER";
export const RESET_PARTICULAR_STATE =  "RESET_PARTICULAR_STATE";
export const SET_IMPERSONATE = "SET_IMPERSONATE";
export const END_IMPERSONATE = "END_IMPERSONATE";
export const SET_DEFAULT_LOCATION = "SET_DEFAULT_LOCATION"

// Actions for login
export const UserInformation = payload => {
  return dispatch => {
    setLocalStorage("token", payload.token);
    dispatch(getUserInformation(payload));
  };
};

export const getUserInformation = payload => ({
  type: SET_TOKEN,
  token: payload.token,
  isAuthenticated: payload.isAuthenticated,
  user: payload.user
});
// action for settingpermission and account Id
export const setPermission_AccountId = payload => {
  return dispatch => {
    dispatch(resetState())
     setLocalStorage("accountId", payload.accountId);
     setLocalStorage("permission", payload.permission);
    dispatch(setPermission(payload));
  };
};
// Action for storing current user
export const CurrentUser = (payload) => ({
  type:CURRENT_USER,
  currentUser:payload
})
export const setPermission = payload => ({
  type: SET_PERMISSION,
  permission: payload.permission,
  accountId: payload.accountId
});

export const notificationToast = value => (
  {
    type: SHOW_TOAST,
    toastValue: value
  }
);
export const clearValue = () => (
  {
    type: DISABLE_TOAST
  }
)
// for modal toast in google map
export const modalToast = value => (
  {
    type: MODAL_TOAST,
    modalToastValue: value
  }
);
export const clearModalToast = () => (
  {
    type: DISABLE_MODAL_TOAST
  }
)

export const accountInformation = (payload) => ({
  type: ACCOUNT_INFORMATION,
  accounts: payload
})

// ACTIONS FOR CUSTOM LOADING
export function loading(flag) {
  return { type: LOADING_IMAGE, flag };
}


export function toastFlag(flag){
return {
  type:TOAST_FLAG,
  toastFlag:flag
}
}

export function setUser(payload){
  return{
    type:SET_USER,
    User:payload
  }
};
// Reset Particular Value of store
export const resetState = () => ({
  type:RESET_PARTICULAR_STATE

});

export const ImpersonateUserInformation = payload => { 
  return dispatch => {
    dispatch(resetState())
    setLocalStorage("impersonate", JSON.stringify(payload));
    setLocalStorage("MasterAccountId", payload.masterAccountId);
    setLocalStorage("accountId", payload.accountId);
    dispatch(ImpersonateUser(payload));
  };
};
// function for setting Impersonation permission via actions
export const ImpersonateUser = (payload) => ({
  type:SET_IMPERSONATE,
  impersonateId: payload.accountId,
  impersonateUserId:payload.user_id,
  isImpersonate:payload.isImpersonate,
  permission:payload.permission,
  globalPermission:payload.globalPermission
  });


  // function for end impersonation
  export const endImpersonation = () => {
    return dispatch => {
      dispatch(resetState())
      removeLocalStorage("impersonate");
      let _accountId = getLocalStorage("MasterAccountId")
      setLocalStorage("accountId", _accountId);
      dispatch(endImpersonateUser());
    };
  };


  // function for setting endImpersonation

  export const endImpersonateUser = () => ({
    type: END_IMPERSONATE,
    permission:getLocalStorage("permission")
    
    });


    // function to set default address 

    export const setDefaultAddress = (payload) => ({
      type:SET_DEFAULT_LOCATION,
      location:payload.coordinates
    })