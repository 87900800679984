import React, { Component } from "react";
import { FooterStyle } from "./Footer.style";

class Footer extends Component {
  render() {
    const year = new Date().getFullYear();
    return (
      <FooterStyle>
        <footer>
          <div className="footer-container alignItems position">
            <div>
              <span className="color">&copy; Croptix {year}. Version 1.0.0</span>
            </div>
          </div>
        </footer>
      </FooterStyle>
    );
  }
}

export default Footer;
