import {
  mapMenuOrder
} from "./../src/utilities/utilities";
import logo from "./assets/citrus_color_green.svg";

const subMenu = {
  UserRead: {
    name: "Users",
    icon: "mr-2 fas fa-user-alt",
    path: "setup-list"
  },
  AccountRead: {
    name: "Account",
    icon: "mr-2 fas fa-cog",
    path: "account"
  },
  ShowAdminRole: {
    name: "Sensors",
    icon: "mr-2 fas fa-mobile-alt",
    path: "setUp-scanners"
  },
  OperatorRead: {
    name: "Operator",
    icon: "mr-2 fas fa-male",
    path: "operator"
  },
  GuestCreate: {
    name: "Guests",
    icon: "mr-2 fas fa-user-alt",
    path: "support-guests"
  },
  BlockRead: {
    name: "Blocks",
    path: "blocks",
    icon: "mr-2 far fa-square"
  },
  TaskRead: {
    name: "Task Template",
    path: "task-template",
    icon: "mr-2 far fa-check-square"
  }
};

export const MainMenu = {
  DashboardRead: {
    name: "Dashboard",
    path: "dashboard",
    icon: "icon-speedometer"
  },
  ResultRead: {
    name: "Scan Results",
    path: "mapped-result",
    icon: "mr-2 far fa-map"
  },
  ReportRead: {
    name: "Reports",
    path: "reports",
    icon: "mr-2 far fa-file-alt"
  },
  LocationRead:{
    name: "Tree Locations",
    logo: logo,
    path: "cs-tree",
    mainMenu:true
  }
};
const CustomerSupport = {
  name: "Customer Support",
  icon: "mr-2 fas fa-first-aid",
  path: "customer-support",
  clickable: true,
  submenu: [
    {
      name: "Account",
      icon: "mr-2 fas fa-cog",
      path: "cs-edit"
    },
    {
      name: "Users",
      icon: "mr-2 fas fa-user-alt",
      path: "cs-users"
    },
    {
      name: "Operator",
      icon: "mr-2 fas fa-male",
      path: "operator"
    },
    {
      name: "Tree Locations",
      logo: logo,
      path: "cs-tree",
      
    },
    {
      name: "Upload Reports",
      icon: " mr-2 fas fa-cloud-upload-alt",
      path: "cs-upload"
    }
  ]
};
let Task = {
  name: "Tasks",
  icon: " mr-2 fas fa-tasks",
  path: "task"
};
/*let Trees = {
  name: "Trees",
  icon: "icon-orange-x20-1",
  path: "cs-tree"
};*/
let SetUp = {
  name: "Setup",
  icon: "mr-2 fas fa-cogs",
  clickable: false,
  submenu: []
};

export class Menubar {
  constructor(props) {
    this.Menu = [];
    this.accountPermission = props.permission
      ? props.permission.split(",")
      : [];
    // let { global_permissions } = getTokenInformation(props.token),
    let { global_permissions } = props.currentUser;
    this.Menu = this.accountPermission
      .map(item => MainMenu[item])
      .filter(item => item);
    if (
      this.accountPermission.includes("UserRead") ||
      this.accountPermission.includes("AccountRead") ||
      this.accountPermission.includes("GuestCreate")
    ) {
      let _setUpOrder = [
        "Users",
        "Account",
        "Blocks",
        "Task Template",
        "Sensors",
        "Operator"
      ];

      SetUp.submenu = this.accountPermission
        .map(item => subMenu[item])
        .filter(item => item);
      SetUp.submenu = mapMenuOrder(SetUp.submenu, _setUpOrder, "name");
      this.Menu.push(SetUp);
      if (this.accountPermission.includes("TaskRead")) {
        this.Menu.push(Task);
      }
    };
    if (
      (global_permissions &&
        global_permissions.includes("support") &&
        !props.isImpersonate) ||
      (props.isImpersonate && props.globalPermission.includes("support"))
    ) {
      this.Menu.push(CustomerSupport);
    }
    let _order = [
      "Dashboard",
      "Scan Results",
      "Reports",
      "Tree Locations",
      "Tasks",
      "Setup",
      "Customer Support"
    ];
    this.Menu = mapMenuOrder(this.Menu, _order, "name");
  }
  menuBarItems() {
    return this.Menu;
  }
}
