export const breakPoints = {
    largeScreen: "@media (min-width: 1200px)",
    mediumScreen:
      "@media only screen and (min-width: 992px) and (max-width: 1199px)",
    smallMediumScreen:
      "@media only screen and (min-width: 768px) and (max-width: 991px)",
    portrait: "@media screen and (orientation: portrait)",
    landscape: "@media screen and (orientation: landscape)",
    mobileScreen: "@media only screen and (max-width: 767px)",
    uptoMediumScreen:
      "@media only screen and (min-width: 0) and (max-width: 1199px)",
    uptoSmallMedium:
      "@media only screen and (min-width: 0px) and (max-width: 991px)",
    smallScreen: "@media (max-width: 580px)",
    smallScreen2: "@media only screen and (max-width: 367px)"
  };