
export const AddUserDataModel= [
    {
        fieldType: "input",
        inputType: "text",
        name: "first",
        label: "First name",
        valid: '[]'
      },
      {
        fieldType: "input",
        inputType: "text",
        name: "last",
        label: "Last name",
        valid: '[]'
      },
      {
        fieldType: "input",
        inputType: "text",
        name: "email",
        label: "Email",
        valid: '["required", "email"]'
      }
]



export const newPin = 
{
    fieldType: "input",
    inputType: "text",
    name: "pin",
    label: "Set New PIN?",
    isCheckbox:true,
    colMd: 3,
   disabled:true
  }
 
