import {
    API,
    BLOCKS,CYRUS_ROUTES
  } from "./../../components/services/api";
  import { notificationToast, clearValue, loading } from "./Login";
  import {setTableData} from './Blocks';

//   function for get Blocks
export const getBlocks = ({callback,fromCs,account_id,isHidden}) => {
  let URL= fromCs?`${CYRUS_ROUTES}/blocks?offset=${0}&limit=${1000}&&account_id=${account_id}`:
  
  isHidden? `${BLOCKS}?hidden=true&&offset=${0}&limit=${1000}`: `${BLOCKS}?offset=${0}&limit=${1000}`
    return dispatch => {
      dispatch(loading(true));
      return API.get(URL, {}, {})
        .then(res => {
          let _res = res.data!==null?res.data:[]
          dispatch(setTableData(_res))
          dispatch(loading(false));
          if (callback) callback(res);
        })
        .catch(err => {
          dispatch(loading(false));
         
          let { message } = err.response.data;
          dispatch(notificationToast({ type: "error", message: message }));
          dispatch(clearValue());
            return err;
        });
    };
  };

// function for getting tree variety
export const getBlockTreeVariety = () => {
  return dispatch => {
    dispatch(loading(true));
    return API.get(`${BLOCKS}/tree_varieties`, {}, {})
      .then(res => {
        dispatch(getTreeVariety(res));
        dispatch(loading(false));
        // if (callback) callback(res);
      })
      .catch(err => {
        dispatch(loading(false));
        // console.log("_err",err.res)
        // if (callback) callback();
        let { message } = err.response.data;
        dispatch(notificationToast({ type: "error", message: message }));
        dispatch(clearValue());

        return err;
      });
  };
};

// function fo post block
export const addBlocks = ({ payload, callback }) => {
  return dispatch => {
    dispatch(loading(true));
    return API.post(BLOCKS, payload, {})
      .then(res => {
        dispatch(loading(false));
        if (callback && res.created_at) {
          callback(res);
          dispatch(
            notificationToast({
              type: "success",
              message: "Block created successfully"
            })
          );
          dispatch(clearValue());
        } else {
          callback(res);
          dispatch(
            notificationToast({
              type: "warning",
              message: "Block already exists"
            })
          );
          dispatch(clearValue());
        }
      })
      .catch(err => {
        dispatch(loading(false));
        if (callback) callback(err);
       

        let { message } = err.response.data;
        dispatch(notificationToast({ type: "error", message: message }));
        dispatch(clearValue());
      });
  };
};


// function for delete blocks 

export const deleteBlocks = ({ id, callback }) => {
  return dispatch => {
    dispatch(loading(true));
    return API.delete(`${BLOCKS}/${id}`, {}, {})
      .then(res => {
        if (callback) callback();
        dispatch(loading(false));
        dispatch(
          notificationToast({
            type: "success",
            message: "Block deleted successfully"
          })
        );
        dispatch(clearValue());
      })
      .catch(err => {
        if (callback) callback();
        dispatch(loading(false));
        let { message } = err.response.data;
        dispatch(notificationToast({ type: "error", message: message }));
        dispatch(clearValue());
      });
  };
};

// function for update blocks
export const updateBlock = ({id,cb,payload}) => {
  return (dispatch) => {
    dispatch(loading(true));
    return API.patch(
      `${BLOCKS}/${id}`,
      payload,
      {}
    )
      .then(res => {
        if(cb)cb(res);
        dispatch(loading(false));
        dispatch(notificationToast({ type: "success", message: "Block updated Successfully" }));
        dispatch(clearValue());
  })
      .catch(err => {
        if(cb)cb();
        dispatch(loading(false));
        let { message } = err.response.data;
        if (err.response.data.status === 500) {
         
          dispatch(
            notificationToast({
              type: "error",
              message: "Failed to update Block"
            })
          );
          dispatch(clearValue());
          

        } else {
          dispatch(notificationToast({ type: "error", message: message }));
          dispatch(clearValue());
        }
      });
  };
};




// Object values for root stock/tree variety
export const TREE_VARIETY = "TREE_VARIETY";
export const BLOCK_TYPE = "BLOCK_TYPE";
export const SET_ACCOUNT_ADDRESS = "SET_ACCOUNT_ADDRESS";
export const IS_NAVIGATE_BLOCK="IS_NAVIGATE_BLOCK";


export const getTreeVariety = payload => ({
  type: TREE_VARIETY,
  treeVariety:payload
});



// function for get Address as default address of account

export const getDefaultAddress = (payload)=>({
  type:SET_ACCOUNT_ADDRESS,
  address:payload.addresses
});

// function for clearing the state

export const isNavigateBlock=(value)=>({
  type:IS_NAVIGATE_BLOCK,
  value
})

