import { TOGGLE_HIDDEN, SET_TABLE_DATA } from "../actions/Blocks";
const initialState = {
    blocks: [],
    showHidden: false,
    tableData: [],
    isNavigateBlock:false
}

const Blocks = (state = initialState, action) => {
    switch (action.type) {
        case TOGGLE_HIDDEN:
            return {
                ...state,
                showHidden: action.toggleValue
            }
        case SET_TABLE_DATA:
            return {
                ...state,
                tableData: action.tableData,
                blocks:action.tableData
            }
           
        default:
            return {
                ...state
            }
    }
};
  
  export default Blocks;
  