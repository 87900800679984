import React from "react";
import { Card, CardFooter, Table, Button, CardHeader } from "reactstrap";
import { ContainerWrapper } from "./AccountList.styles";
import { logOutFromAuth0,setCookie,getCookieValue } from "./../../utilities/utilities";
import {showToast} from './../../components/config/Toast/Toast'
import { setPermission } from "../../store/actions/Login";

class AccountList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isCheckedId: this.props.fromHeader&&!this.props.isImpersonate ?
        (this.props.accountId) : null,
      isdisabled: this.props.fromHeader && !this.props.isImpersonate ? false : true,
      list: null
    };
    
  }
  componentDidMount = () => {
    // if cookie exists set the account directly and navigate that directly
    let _sessionValue = getCookieValue('account');
    let _loggedInUser = getCookieValue('user');
    if(_sessionValue &&!this.props.fromHeader&&!this.props.isImpersonate){
      this.setPermission(JSON.parse(_sessionValue),_loggedInUser);
    }
  }
  setPermission=(_sessionValue,_loggedInUser)=>{
    // to check if logged in user is same as that of cookie user
    if(_loggedInUser === this.props.loginReducer.user.email){
      // to check if account permission has been changed in 90 days
      let _isSameAccountPermission = JSON.stringify(this.props.list).includes(JSON.stringify(_sessionValue));
      if(_isSameAccountPermission){
      this.setList(_sessionValue)
        }
      // if permission has been changed map the lis based on account id in cookie and list
      else{
        let _obj = this.props.list&&this.props.list.map(item=>{
          if(item.id === _sessionValue.id){
            this.setList(item)
           
          }
        })
      }
   
  }
  }
  setList = (value) => {
    this.setState({
     
      list:value
    },()=>{
      this.accountPermission();
      
    });
  }
  accountPermission =() => {
    let permissionObj = {
        permission: this.state.list.permissions,
        accountId: this.state.list.id
      };
      this.props.setPermission_AccountId(permissionObj);
      this.props.history.push("/dashboard");
  }
// handling radio button change event
  handleChange = (event, list) => {
    this.setState({
      isCheckedId: list.id,
      isdisabled: false,
      list: list
    });
  };
  // oncancel 
  onCancel = () => {
    if (!this.props.fromHeader) {logOutFromAuth0()}
    else { this.props.modalClose() }
  };
  onSave = () => {
    // if a person remains in same account and clicks ok
    if (this.state.list === null && this.props.fromHeader) {
      {
        this.props.modalClose()
      }
    }
    else {
      // set cookie of user and account details
      setCookie('account',JSON.stringify(this.state.list),90);
      setCookie('user',this.props.loginReducer.user.email,90);
      this.accountPermission();
      
      if (this.props.fromHeader) {
        let toastObj = { type: "success", message: `Successfully changed to ${this.state.list.description}` };
        showToast(toastObj);
        this.props.modalClose()
      }
    }

  };
  row =['Name',"Description","Role"];

  render() {
    return (
      <ContainerWrapper style={{ width: this.props.fromHeader === true ? "100%" : "" }}>
        <CardHeader className="cardHeader">Select an Account</CardHeader>
        <Card>
          <Table striped bordered hover responsive>
            <thead>
              <tr className="tableTr">
                <th style={{ width: "50px" }} />
                {this.row&&this.row.map((item,index)=>{return<th key={index+1}>{item}</th>})}
                
              </tr>
            </thead>
            <tbody className="tbodyScroll">
              {this.props.list &&
                this.props.list.map(list => {
                  return (
                    <tr key={list.id} className="tableTr">
                      <td className="tdwidth" >
                        <label className="checkboxStyles">
                          <input
                            type="radio"
                            name="radio1"
                            value={list.id}
                            onChange={e => this.handleChange(e, list)}
                            checked={list.id === this.state.isCheckedId}
                          />
                        </label>
                      </td>
                      <td>
                        <div className="media">
                          <div className="media-body">{list.name}</div>
                        </div>
                      </td>
                      <td>
                        {list.description}
                        <span />
                      </td>
                      <td>{list.role}</td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>

          <CardFooter style={{padding:this.props.fromHeader?"0":"",paddingTop:"10px"}}>
            <div className="d-flex align-items-center">
              <div className="ml-auto">
                <Button
                  className="bg-croptix-color"
                  type="button"
                  disabled={this.state.isdisabled}
                  onClick={() => this.onSave()}
                >
                  Ok
                </Button>
                <Button
                  className="bg-croptix-color"
                  type="button"
                  onClick={() => this.onCancel()}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </CardFooter>
        </Card>
      </ContainerWrapper>
    );
  }
}

export default AccountList;
