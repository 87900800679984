import { createStore, applyMiddleware } from "redux";
import reducers from "./reducers/reducers";
import middlewares from "./middlewares/middlewares";
import thunk from "redux-thunk";
import logger from "redux-logger";
import { RESET_PARTICULAR_STATE } from "./actions/Login";

import { updateTheme } from "./middlewares/themes.middleware.js";

import { persistedState, saveState } from "./persisted.store.js";

const appReducer = (state, action) => {
  if (action.type === RESET_PARTICULAR_STATE) {
    let stateItemsValue = [
      "Dashboard",
      "BlockReducer",
      "Blocks",
      "CustomerSupport",
      ];

    stateItemsValue &&
      stateItemsValue.map(item => {
        return (state[item] = undefined);
      });
  }

  return reducers(state, action);
};
export default function configureStore() {
  const store = createStore(
    appReducer,
    persistedState, // second argument overrides the initial state
    applyMiddleware(...middlewares, thunk
        // , logger
        )
  );

  // add a listener that will be invoked on any state change
  store.subscribe(() => {
    saveState(store.getState());
  });

  // Update the initial theme
  updateTheme(store.getState());

  return store;
}
