import {
  SET_TOKEN,
  SET_PERMISSION,
  SHOW_TOAST,
  DISABLE_TOAST,
  ACCOUNT_INFORMATION,
  CURRENT_USER,
  LOADING_IMAGE,
  DISABLE_MODAL_TOAST,
  MODAL_TOAST,
  TOAST_FLAG,
  SET_USER,
  SET_IMPERSONATE,
  END_IMPERSONATE,SET_DEFAULT_LOCATION
} from "../actions/Login";

const initialSettings = {
  token: null,
  isAuthenticated: false,
  permission: null,
  accountId: null,
  user: {},
  isToast: false,
  toastValue: {},
  accounts: [],
  currentUser: {},
  loader: false,
  isMOdalToast: false,
  modalToastValue: {},
  toastFlag: true,
  User: [],
  impersonateId: null,
  impersonateUserId: null,
  isImpersonate: false, 
  globalPermission: "",
  defaultLocation:{}
};

const loginReducer = (state = initialSettings, action) => {
  switch (action.type) {
    case SET_TOKEN:
      return {
        ...state,
        token: action.token,
        isAuthenticated: action.isAuthenticated,
        user: action.user
      };
    case SET_PERMISSION:
      return {
        ...state,
        permission: action.permission,
        accountId: action.accountId
      };
    case SHOW_TOAST:
      return {
        ...state,
        isToast: true,
        toastValue: action.toastValue
      };
    case DISABLE_TOAST:
      return {
        ...state,
        isToast: false,
        toastValue: {}
      };
    case ACCOUNT_INFORMATION:
      return {
        ...state,
        accounts: action.accounts
      };
    case CURRENT_USER:
      return {
        ...state,
        currentUser: action.currentUser
      };
    case LOADING_IMAGE:
      return {
        ...state,
        loader: action.flag
      };
    case MODAL_TOAST:
      return {
        ...state,
        isMOdalToast: true,
        modalToastValue: action.modalToastValue
      };
    case DISABLE_MODAL_TOAST:
      return {
        ...state,
        isMOdalToast: false,
        modalToastValue: {}
      };
    case TOAST_FLAG:
      return {
        ...state,
        toastFlag: action.toastFlag
      };
    case SET_USER:
      return {
        ...state,
        User: action.User
      };
    case SET_IMPERSONATE:
      return {
        ...state,
        impersonateId: action.impersonateId,
        impersonateUserId: action.impersonateUserId,
        isImpersonate: action.isImpersonate,
        permission:action.permission,
        globalPermission:action.globalPermission
      };
      case END_IMPERSONATE:
      return {
        ...state,
        impersonateId: null,
        impersonateUserId:null,
        isImpersonate: false,
        permission:action.permission,
        globalPermission:""
      };
      case SET_DEFAULT_LOCATION:
      return {
        ...state,
        defaultLocation:action.location
      }
    default:
      return state;
  }
};

export default loginReducer;
