import { toast } from "react-toastify";
import {
  DEFAULT_TOAST_POSITION,
  TOAST_ANIMATION_CLASS,
  CROPTIX_BUTTON_COLOR,
  OK,
  CANCEL,
  LOG_OUT,
  ALERT_ANIMATION_CLASS,
  TOAST_WARNING
} from "./../../config/textConstant/textConstant";

export function showToast(toastValue) {
  toast(toastValue.message, {
    type: toastValue.type,
    position: DEFAULT_TOAST_POSITION,
    className: TOAST_ANIMATION_CLASS,
    hideProgressBar: true,
    autoClose: 1000
  });
}

export const AlertObject = {
  text: LOG_OUT,
  type: TOAST_WARNING,
  showCancelButton: true,
  icon: TOAST_WARNING,
  closeOnClickOutside: false,
  className: ALERT_ANIMATION_CLASS,
  buttons: {
    confirm: {
      text: OK,
      className: CROPTIX_BUTTON_COLOR
    },
    cancel: {
      text: CANCEL,
      value: null,
      visible: true,
      className: CROPTIX_BUTTON_COLOR,
      closeModal: true
    },
    confirm: {
      text: OK,
      className: CROPTIX_BUTTON_COLOR
    }
  }
};
