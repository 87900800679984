import React from "react";
import { connect } from "react-redux";
import Header from "./Header/Header";
import Sidebar from "./Sidebar";
import Offsidebar from "./Offsidebar";
import Footer from "./Footer/Footer";
import LoaderComponent from "./../../containers/Loader/Loader";
import { BaseStyle } from './Header/Header.style';


const Base = props => (
  <BaseStyle>
    <div className={props.loading ? "wrapper postionWrapper" : "wrapper  listwrapper"}>
      <Header />
      <Sidebar />

      <Offsidebar />

      {/* {props.loading && <LoaderComponent />} */}
      <section className="section-container containerStyle">
        {props.children}
      {props.loading && <LoaderComponent />}

        {/* <LoaderComponent /> */}
      </section>
      <Footer />
    </div>
  </BaseStyle>
);

const mapDispatchToProps = dispatch => {
  return {};
};
const mapStateToProps = state => {
  return {
    loading: state.loginReducer.loader,
    login: state.loginReducer
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Base);
