import axios from "axios";
import { onResponse, onResponseError } from "./responseInterceptor";
import { onRequest, onRequestError } from "./requestInterceptor";

export const BASE_URL = process.env.REACT_APP_BASE_URL;

export const API = axios.create({
  baseURL: BASE_URL
});

API.interceptors.request.use(config => onRequest(config), onRequestError);
API.interceptors.response.use(onResponse, onResponseError);

export const SET_UP_LIST_USERS = "api/v1/users";
export const GET_ACCOUNTS = "api/v1/user/accounts";
export const SET_UP_GET_ACCOUNT = "api/v1/account";
export const DELETE_USER = "api/v1/users";
export const CHANGE_PASSWORD ="api/v1/user/resetpass";
export const GET_CURRENT_USER ="api/v1/user";
export const GET_CUSTOMER_SUPPORT_ACCOUNT = "api/v1/cyrus/accounts";
export const BLOCKS="api/v1/blocks";
export const TASKS = "api/v1/tasks";
export const TASK_TEMPLATE = "api/v1/task_templates";
export const REPORT_FILES = "api/v1/account/files";
export const LOGOUT_API = process.env.REACT_APP_AUTH0_LOGOUT_API;
export const AUDIENCE_AUTH0 = process.env.REACT_APP_AUDIENCE_AUTH0;
export const SESSIONS = "api/v1/sessions";
export const NOTIFICATIONS ="api/v1/user/notifications";
export const RESULTS ="api/v1/results";
export const CS_USERS = "api/v1/cyrus/users";
export const SENSORS = "api/v1/sensors";
export const ADD_ACCOUNT_ADDRESS= "api/v1/account/addresses";
export const CYRUS_TREES="api/v1/cyrus/locations";
export const TREES="api/v1/locations";
export const OPERATORS = "api/v1/operators";
export const CYRUS_OPERATORS = "api/v1/cyrus/operators"
export const CYRUS_ROUTES = "api/v1/cyrus";
export const WEATHER_API = process.env.REACT_APP_WEATHER_API;
// export const REDIRECTION_URL_AUTH0 = "https://localhost:3000";
// export const LOGOUT_REDIRECTION_URL = encodeURIComponent("https://localhost:3000");
export const REDIRECTION_URL_AUTH0 = process.env.REACT_APP_CROPTIX_URL;
export const LOGOUT_REDIRECTION_URL = encodeURIComponent(process.env.REACT_APP_CROPTIX_URL)
