import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withNamespaces, Trans } from 'react-i18next';
import { Link, withRouter } from 'react-router-dom';
import { Collapse, Badge } from 'reactstrap';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../../store/actions/actions';
import { updateMenuItems, setMenuItems, openInAnotherTab } from '../../store/actions/setMenuItems';

import SidebarRun from './Sidebar.run';
import SidebarUserBlock from './SidebarUserBlock';
import logo from './../../assets/orange x20.svg';


import{Menubar} from '../../Menu.js';
import { getIdFromUrl, getSelectedAccountFromStorage } from '../../utilities/utilities';
/** Component to display headings on sidebar */
const SidebarItemHeader = ({item}) => (
    <li className="nav-heading">
        <span><Trans>{item.heading}</Trans></span>
    </li>
)

/** Normal items for the sidebar */
const SidebarItem = ({item, isActive}) => (
    <li className={ isActive ? 'active' : '' }>
        <Link to={item.path} title={item.name}>
            {item.label && <Badge tag="div" className="float-right" color={item.label.color}>{item.label.value}</Badge>}
            {item.icon && <em className={item.icon}></em>}
            {item.logo&&<img  className="styleClass" style={{margin:item.mainMenu?"0px 15px 0px 0em":"0 15px 0 -2em"}}src={logo}/>}
            <span><Trans >{item.name}</Trans></span>
        </Link>
    </li>
)

/** Build a sub menu with items inside and attach collapse behavior */
const SidebarSubItem = ({ item, isActive, handler, children, isOpen, editable }) => {
    return (
        <li className={isActive ? 'active' : ''}>
            <div className="nav-item" onClick={handler}>
                {item.icon && <em  className={item.icon}></em>}

                <span ><Trans >{item.name}</Trans></span>
                {editable && <Link to={{ pathname: item.path, state:{ id: getIdFromUrl() } }}><em  className='mr-2 fas fa-pencil-alt padding bg-croptix-icon-color' style={{paddingLeft:"4px"}}></em></Link>}
            </div>
            <Collapse isOpen={isOpen}>
                <ul 
                 id={item.path} className="sidebar-nav sidebar-subnav">
                    {children}
                </ul>
            </Collapse>
        </li>
    )
}


/** Component used to display a header on menu when using collapsed/hover mode */
const SidebarSubHeader = ({item}) => (
    <li className="sidebar-subnav-header">{item.name}</li>
)


class Sidebar extends Component {
      constructor(props){
        super(props);
        this.state = {
            collapse: {},
          }
        this.getSideBar(props);

    }
    componentDidMount() {
        SidebarRun(this.navigator, this.closeSidebar);
        this.buildCollapseList();
        this.props.history.listen(this.closeSidebar);
        
    }
    getSideBar = (_props) => {
        
        const MenuItems = new Menubar(_props);
        let _menu = MenuItems.menuBarItems()
        this.item = _menu;
       
        if (getSelectedAccountFromStorage(getIdFromUrl())) {
          let selectedAccountData = getSelectedAccountFromStorage(getIdFromUrl());
          this.props.setMenuItems(selectedAccountData);
        } else {
          this.props.setMenuItems(_menu);
        }
        
        if (localStorage.getItem(`data_${getIdFromUrl()}`)) {
            let currentAccount = getIdFromUrl();
            let data = JSON.parse(localStorage.getItem(`data_${currentAccount}`));
            this.props.setMenuItems(data);
            this.props.openInAnotherTab(true);
        }
        this.props.updateMenuItems(this.item)
 
    } 
    componentWillReceiveProps=(newProps)=>{
        if (
        (newProps.permission !== this.props.permission) || (JSON.stringify(newProps.currentUser)!== JSON.stringify(this.props.currentUser))
          ) {
           this.getSideBar(newProps)

          }
    }
    updateSideBar = () => {
        this.buildCollapseList(this.props.menuItems);
        this.props.history.listen(this.closeSidebar);
        SidebarRun(this.navigator, this.closeSidebar);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.updated !== this.props.updated) {
            this.updateSideBar()
        }
        if (prevProps.enableEdit !== this.props.enableEdit) {
            this.updateSideBar()
        }
        if (prevProps.popover !== this.props.popover) {
            this.updateSideBar()
        }
        if((prevProps.isImpersonate !== this.props.isImpersonate)){
           
        const MenuItems = new Menubar(this.props);
        let _menu = MenuItems.menuBarItems()
        this.item = _menu;
        this.props.setMenuItems(_menu);
        }
    }

    closeSidebar = () => {
        this.props.actions.toggleSetting('asideToggled');
    }

    /** prepare initial state of collapse menus. Doesnt allow same route names */
    buildCollapseList = (items = this.item) => {
        let collapse = {};
        let filterItem = items.filter(({ heading }) => !heading);
        filterItem.forEach(({ name, path, submenu }) => {
            if(path==="customer-support"){
                collapse[name]=true
            }
              else{
                collapse[name] = this.routeActive(submenu ? submenu.map(({path})=>path) : path)
                
              }  
        })
        this.setState({collapse});

    }

    navigator = route => {
        this.props.history.push(route);
    }

    routeActive(paths) {
        paths = Array.isArray(paths) ? paths : [paths];
        return paths.some(p => this.props.location.pathname.indexOf(p) > -1)
    }

    toggleItemCollapse(stateName) {
        for (let c in this.state.collapse) {
            if (this.state.collapse[c] === true && c !== stateName)
                this.setState({
                    collapse: {
                        [c]: false
                    }
                });
        }
        this.setState({
            collapse: {
                [stateName]: !this.state.collapse[stateName]
            }
        });
    }

    getSubRoutes = item => item.submenu.map(({path}) => path)

    /** map menu config to string to determine which element to render */
    itemType = item => {
        if (item.heading) return 'heading';
        if (!item.submenu) return 'menu';
        if (item.submenu) return 'submenu';
    }


    render() {
        let { menuItems } = this.props;
        let menus = menuItems ? menuItems : this.item;
        return (
            <aside className='aside-container'>
                
                { /* START Sidebar (left) */ }
                <div className="aside-inner">
                    <nav data-sidebar-anyclick-close="" className="sidebar">
                        { /* START sidebar nav */ }
                        <ul className="sidebar-nav">
                            { /* START user info */ }
                            <li className="has-user-block">
                                <SidebarUserBlock/>
                            </li>
                            { /* END user info */ }

                            {
                                menus.map((item, i) => {
                                    // heading
                                    if(this.itemType(item) === 'heading'){
                                        return (
                                            <SidebarItemHeader item={item} key={i} />
                                        )
                                    }
                                    else {
                                        if(this.itemType(item) === 'menu')
                                            return (
                                                <SidebarItem isActive={this.routeActive(item.path)} item={item} key={i} />
                                            )
                                        if (this.itemType(item) === 'submenu' && !item.clickable) {
                                            if (item.editable) {
                                                return [
                                                    <SidebarSubItem item={item}
                                                        isOpen={this.state.collapse[item.name]}
                                                        handler={this.toggleItemCollapse.bind(this, item.name)}
                                                        isActive={this.routeActive(item.path)}
                                                        key={i}
                                                        editable={item.editable}
                                                    >
                                                         <SidebarSubHeader item={item} key={i}/> 
                                                        {
                                                            item.submenu.map((subitem, i) =>
                                                                <SidebarItem key={i} item={subitem} isActive={this.routeActive(subitem.path)} />
                                                            )
                                                        }
                                                    </SidebarSubItem>
                                                ] 
                                            }
                                            return [
                                                <SidebarSubItem item={item} isOpen={this.state.collapse[item.name]} handler={ this.toggleItemCollapse.bind(this, item.name) } isActive={this.routeActive(this.getSubRoutes(item))} key={i}>
                                                     <SidebarSubHeader item={item} key={i}/> 
                                                    {
                                                        item.submenu.map((subitem, i) =>
                                                            <SidebarItem key={i} item={subitem} isActive={this.routeActive(subitem.path)} />
                                                        )
                                                    }
                                                </SidebarSubItem>
                                            ]
                                            
                                        } else if (this.itemType(item) === 'submenu' && item.clickable) {
                                            return (
                                                <SidebarItem isActive={this.routeActive(item.path)} item={item} key={i} />
                                            )
                                        }
                                            
                                    }
                                    return null; // unrecognized item
                                })
                            }
                        </ul>
                        { /* END sidebar nav */ }
                    </nav>
                </div>
                { /* END Sidebar (left) */ }
            </aside>
        );
    }
}

Sidebar.propTypes = {
    actions: PropTypes.object,
    settings: PropTypes.object
};

const mapStateToProps = state => ({
    settings: state.settings,
    permission: state.loginReducer.permission,
    token: state.loginReducer.token,
    menuItems: state.setMenuItems.menuItems,
    oldMenuItems: state.setMenuItems.oldMenuItems,
    updated: state.setMenuItems.updated,
    isOpenInModal: state.setMenuItems.isOpenInModal,
    enableEdit: state.setMenuItems.enableEdit,
    isImpersonate:state.loginReducer.isImpersonate,
    globalPermission:state.loginReducer.globalPermission,
    currentUser:state.loginReducer.currentUser
})
const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch),
    setMenuItems: (menuItems) => dispatch(setMenuItems(menuItems)),
    updateMenuItems: (menuItems) => dispatch(updateMenuItems(menuItems)),
    openInAnotherTab: (value) => dispatch(openInAnotherTab(value)),

})
    
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withNamespaces('translations')(withRouter(Sidebar)));