import React, { Component } from "react";
import PropTypes from "prop-types";
import swal from "sweetalert";
import { withRouter } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import {
  Modal,
  ModalHeader,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "../../../store/actions/actions";
import HeaderRun from "./../Header.run";
import { HeaderStyle, HeaderWrapper } from "./Header.style";
import logo from "./../../../assets/CroptixLogoColor.png";
import { logOutFromAuth0, mailToLink } from "./../../../utilities/utilities";
import AddUser from "./../../../components/SetUp/AddUser";
import { updateUser } from "./../../../store/actions/SetupListUsers";
import AccountList from "./../../../pages/SplashScreen/AccountsList";
import {
  setPermission_AccountId,
  resetPassword,
  getCurrentUser,
  toastFlag,
  UserAccounts
} from "./../../../store/actions/Login";
import { LOG_OUT } from "./../../config/textConstant/textConstant";
import { showToast, AlertObject } from "./../../config/Toast/Toast";
import {
  getNotification,
  deleteNotificationById
} from "./../../../store/actions/DashboardAction";
import Notification from "./../../Dashboard/NotificationModal";

class Header extends Component {
  state = {
    isModal: false,
    isAccountModal: false,
    notification: [],
    isNotificationMOdal: false
  };

  componentDidMount() {
    HeaderRun();
    this.updateHeader();
  }
  updateHeader = () => {
    this.props.getCurrentUser();
    this.props.UserAccounts({ cb: this.onAccountSuccess });
  };
  getNotificationList = () => {
    this.props.getNotification(this.OnNotificationSuccess);
  };
  OnNotificationSuccess = res => {
    if (res.data && res.data.length) {
      this.setState({ notification: res.data });
    } else {
      this.setState({ isNotificationMOdal: false, notification: [] });
    }
  };
  componentWillReceiveProps = newProps => {
    if (newProps.notification) {
      this.setState({ notification: newProps.notification });
    }
    if (newProps.isImpersonate != this.props.isImpersonate) {
      this.updateHeader();
    }
  };
  onAccountSuccess = res => {
  };
  toggleCollapsed = e => {
    e.preventDefault();
    this.props.actions.toggleSetting("isCollapsed");
    this.resize();
  };

  toggleAside = e => {
    e.preventDefault();
    this.props.actions.toggleSetting("asideToggled");
  };

  resize() {
    var evt = document.createEvent("UIEvents");
    evt.initUIEvent("resize", true, false, window, 0);
    window.dispatchEvent(evt);
  }
  logOut() {
    AlertObject.text = LOG_OUT;
    swal(AlertObject).then(isLogOut => {
      if (isLogOut) {
        logOutFromAuth0();
      }
    });
  }

  toggleDD = isOpen => {
    this.setState({
      [isOpen]: !this.state[isOpen]
    });
  };
  modalClose = () => {
    this.setState({ isModal: false, isAccountModal: false });
  };
  changePassword = () => {
    this.props.resetPassword();
  };
  componentWillUnmount = () => {
    // toast.dismiss();
  };

  render() {
    const { toastValue, currentUser } = this.props;
    return (
      <HeaderWrapper>
        {this.props.toastFlagAdd && <ToastContainer />}
        {this.props.istoast && showToast(toastValue)}
        <header className="topnavbar-wrapper zIndex">
          <nav className="navbar topnavbar flexdisplay">
            <div className="navbar-header" />

            <ul className="navbar-nav mr-auto flex-row">
              <li className="nav-item">
                <a
                  href=""
                  className="nav-link hamburgerIconColor d-none d-md-block d-lg-block d-xl-block"
                  onClick={this.toggleCollapsed}
                >
                  <em className="fas fa-bars" />
                </a>
                <a
                  href=""
                  className="nav-link sidebar-toggle d-md-none"
                  onClick={this.toggleAside}
                >
                  <em className="fas fa-bars hamburgerIconColor" />
                </a>
              </li>
            </ul>

            <HeaderStyle>
              <img src={logo} alt="logo" className="height" />
            </HeaderStyle>
            <Modal isOpen={this.state.isModal}>
              <ModalHeader style={{ paddingLeft: "25px" }}>
                <span style={{ fontWeight: "bold", fontSize: "1.3em" }}>
                  Edit User
                </span>
              </ModalHeader>
              <AddUser
                {...this.props}
                isModalClose={this.modalClose}
                id={currentUser.id}
                isNewUser={false}
                User={currentUser}
                fromHeader={true}
              />
            </Modal>
            <Modal isOpen={this.state.isAccountModal}>
              <ModalHeader style={{ paddingLeft: "25px" }}>
                <span style={{ fontWeight: "bold", fontSize: "1.3em" }}>
                  Account List
                </span>
              </ModalHeader>
              <AccountList
                {...this.props}
                list={this.props.accounts}
                fromHeader={true}
                modalClose={this.modalClose}
              />
            </Modal>
            <Modal isOpen={this.state.isNotificationMOdal}>
              <ModalHeader>
                <span style={{ fontWeight: "bold", fontSize: "1.3em" }}>
                  System Notifications
                </span>
              </ModalHeader>
              <Notification
                {...this.props}
                notification={this.state.notification}
                getNotificationList={this.getNotificationList}
                modalClose={() => this.setState({ isNotificationMOdal: false })}
              />
            </Modal>
            <div className="paddingDiv">
              <ButtonDropdown
                className="selectClass"
                isOpen={this.state[`button`]}
                toggle={() => this.toggleDD(`button`)}
              >
                <DropdownToggle caret className="selectClass">
                  {this.props.currentUser.email}
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem
                    onClick={() => this.setState({ isModal: true })}
                  >
                    User Settings
                  </DropdownItem>
                  {this.props.accounts.length >= 2 && (
                    <DropdownItem
                      onClick={() => this.setState({ isAccountModal: true })}
                    >
                      Change Account
                    </DropdownItem>
                  )}
                  <DropdownItem onClick={() => this.changePassword()}>
                    Change Password
                  </DropdownItem>
                  <DropdownItem onClick={() => mailToLink()}>
                    Contact Support
                  </DropdownItem>
                  <DropdownItem onClick={() => this.logOut()}>
                    Sign Out
                  </DropdownItem>
                </DropdownMenu>
              </ButtonDropdown>
            </div>
            {this.props.location.pathname === "/dashboard" &&
              this.state.notification.length > 0 && (
                <div
                  className="margin"
                  onClick={() => this.setState({ isNotificationMOdal: true })}
                >
                  <em className="fa-2x mr-2 fas fa-exclamation"></em>
                </div>
              )}
          </nav>
        </header>
      </HeaderWrapper>
    );
  }
}

Header.propTypes = {
  actions: PropTypes.object,
  settings: PropTypes.object
};

const mapStateToProps = state => ({
  settings: state.settings,
  istoast: state.loginReducer.isToast,
  toastValue: state.loginReducer.toastValue,
  accountId: state.loginReducer.accountId,
  permission: state.loginReducer.permission,
  accounts: state.loginReducer.accounts,
  currentUser: state.loginReducer.currentUser,
  isModalToast: state.loginReducer.isMOdalToast,
  toastFlagAdd: state.loginReducer.toastFlag,
  modalToastValue: state.loginReducer.modalToastValue,
  notification: state.Dashboard.notification,
  loginReducer: state.loginReducer,
  isImpersonate: state.loginReducer.isImpersonate
});
const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions, dispatch),
  updateUser: (payload, id, cb, flag) =>
    dispatch(updateUser(payload, id, cb, flag)),
  setPermission_AccountId: payload =>
    dispatch(setPermission_AccountId(payload)),
  resetPassword: () => dispatch(resetPassword()),
  getCurrentUser: () => dispatch(getCurrentUser()),
  getNotification: callback => dispatch(getNotification(callback)),
  deleteNotificationById: payload => dispatch(deleteNotificationById(payload)),
  toastFlag: flag => dispatch(toastFlag(flag)),
  UserAccounts: cb => dispatch(UserAccounts(cb))
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Header)
);
