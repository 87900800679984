import styled from "styled-components";
import logo from "./../../assets/citrus_grove-blurred.jpg";
import { breakPoints } from "./../../components/config/breakpoints";

export const ContainerWrapper = styled.div`
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 15px;
  padding-bottom: 15px;
  width: 50%;
  margin: 0 auto;
  ${breakPoints.mobileScreen} {
    width: 100%;
  }
  .blockClass{
    width:100% !important;
    padding-bottom:33px !important;
  }
  .buttonColor {
    margin-left: 7px;
    color: white;
    background-color: #bb7b03 !important;
  }
  .buttonColor:hover {
    color: white !important;
  }
  .cardHeader {
    background-color: rgb(241, 231, 216);
    color: #656565;
    font-weight: bold;
    font-size:17px;
    ${breakPoints.mobileScreen} {
      font-size:15px;
  }
  }
  button:disabled {
    background: #dddddd !important;
    background-color: #dddddd !important;
    cursor: not-allowed;
  }
  .checkboxStyles {
    margin: 0 auto;
    padding-left: 12px;
  }
  .tableTr {
    display: table;
    width: 100%;
    table-layout: fixed;
  }
  .tdwidth {
    width: 50px;
    text-align: center;
  }
  .tdWidthOperator{
    width: 100px;
    text-align: center;
  }
  .tbodyScroll {
    max-height: 250px;
    overflow: auto;
    display: block;
  }
`;
export const SplashWrapper = styled.div`
  height: 100% !important;
  width: 100% !important;
  /* background-color: lightgray; */
  display: flex;
  vertical-align: middle;
  align-items: center;
  background-image: url(${logo});
  background-position: bottom;
  /* background-size: 100%; */
  .loaderWrapperStyle{
background-color:transparent !important;
  }
`;
