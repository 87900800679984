import styled from 'styled-components';
import { breakPoints } from "./../../config/breakpoints";


export const HeaderStyle = styled.figure`
 width: 100%;
    text-align: center;
    margin: 0;
`
export const HeaderWrapper = styled.div`
.flexdisplay{
    flex-wrap:nowrap;
    border-bottom:1px solid #cccccc;
}
.hamburgerIconColor {
    color:black !important;
}
.zIndex{
    z-index:1049
}
.buttonPaddingChange{
    padding-left: 24px;
     padding-right: 24px;
}
.margin{
    margin-right:12px;
    cursor:pointer;
    font-size:8px;
    color:red;
}
.height{
    height: 28px;
}
.logOutColor{
    color: #bb7b03;
    cursor:pointer;
    font-size: 22px;
    padding-right: 20px;
    ${breakPoints.mobileScreen} {
      padding-right:10px;
  }
}
.paddingDiv{
    padding-left:15px;
    padding-right:20px;
    ${breakPoints.mobileScreen} {
      padding-right:12px;
  }

}
.modalButtonColor {
 background-color:#F9A604 !important;
}
.dropdown-menu {
    width: 100%;
}
.selectClass{
    /* background-color: #bb7b03 !important; */
    color: #929292;
    border-radius: 3px;
    padding-left: 2px;
    padding-right: 1px;
    border: none;
    outline: none;
    background-color: transparent;
    font-size: 16px;
    font-weight: bold;
    width:100%;
    min-width: 163px;
    ${breakPoints.mobileScreen} {
     font-size:13px;
  }
}
`
export const BaseStyle = styled.div`
.postionWrapper{
    position:absolute !important;
    height:100%;
    min-height:100%;
    overflow-y:hidden;
}
.listwrapper{
    height:100%;
    min-height:100%;
    position:fixed !important;
    overflow:hidden;
    // overflow-y:scroll;
}
.containerStyle {
    overflow: auto;
    overflow-x: hidden;
    //height: calc(100vh - 99px) !important;

    
    ::-webkit-scrollbar-track
    {
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
        background-color: #F5F5F5;
    }

    ::-webkit-scrollbar
    {
        width: 10px;
        background-color: #F5F5F5;
    }

    ::-webkit-scrollbar-thumb
    {
        border-radius: 10px;
        background-image: -webkit-gradient(linear, left bottom, left top, color-stop(0.44,#9e9e9e61), color-stop(0.72,#9e9e9e9e), color-stop(0.86,#9e9e9ec7));
    }
}

`


export const WrapperStyle = styled.div`
/* padding-top:0; */
.wrapperStyle {
    // padding: 0 20px;
    // margin-top: 47px;
    /* padding-top:0; */
}
`