import React from "react";
import { Col, Input } from "reactstrap";
import FormValidator from "./../config/ValidationRules/FormValidation";

/*Reusable form container */
class FormContainer extends React.Component {
  oldError;
  submit = false;
  isChanged = false;
  constructor(props) {
    super(props);
    this.state = {
      formRegister: this.props.formRegister ? this.props.formRegister : {},
      valid: {},
      error: {}
    };
    this.props.fields.forEach(element => {
      if (!this.state.formRegister.hasOwnProperty(element.name)) {
        this.state.formRegister = {
          ...this.state.formRegister,
          [element.name]: element.value
            ? element.value
            : element.fieldType == "select"
            ? element.options[0]
            : ""
        };
      }
      this.state.valid = { ...this.state.valid, [element.name]: false };
      this.state.error = { ...this.state.error, [element.name]: null };
    });
  }

  validateOnChange = data => event => {
    this.submit = true;
    this.isChanged = true;
    const input = event.target;
    const value = input.value;
    const result = FormValidator.validate(input);
    let validType = false;
    let errorType = null;
    if (Object.values(result).includes(true)) {
      validType = true;
      errorType = result;
    }
    this.setState(
      prevState => ({
        valid: {
          ...prevState.valid,
          [input.name]: validType
        },
        error: {
          ...prevState.error,
          [input.name]: errorType
        },
        formRegister: {
          ...prevState.formRegister,
          [input.name]:
            data.fieldType == "select"
              ? data.default
                ? data.id
                  ? data.options.filter(
                      option =>
                        option[data.id] ==
                        parseInt(input[input.selectedIndex].id)
                    )[0]
                  : data.options.filter(
                      option => option[data.default] == value
                    )[0]
                : value
              : value
        }
      }),
      () => {
        
        this.onSucess(data);
      }
    );
  };
  onSucess = data => {
    if (data.fromPathsForRanches) {
      this.props.OnRanchesChange(this.state.formRegister);
      this.isChanged = false;
    }
    // to plot the path inside a block
    // if (data.fromPaths) {
    //   if (data.fromTask === true) {
    //     this.isChanged = false;
    //   }
    //   this.props.blockChange(this.state.formRegister);
    // }
    // if (data.fromTasks) {
    //   this.props.pathChange(this.state.formRegister);
    // }
    if(data.fromTask){
      // console.log("_this.state.formRegister",this.state.formRegister);
      this.props.onTemplateChange(this.state.formRegister); 
      this.isChanged = false;
      this.submit =false
    }
  };
  hasError = (label, inputName,data) => {
    const errorList = this.state.error[inputName];
    let errors = [];
    if (errorList) {
      Object.keys(errorList).forEach(key => {

        if (errorList[key] === true) {
          if (key === "required") {
            errors.push(`Field is required`);
          }
          else if(key==="rotateGrid"||key==="skewGrid"||key ==="max"){
            errors.push(data.errorMsg)
          }
          
          else if(key === 'numericNotRequired' ){
            errors.push(`Field should be a valid number`);
          } else {
            errors.push(`Field should be  a valid ${key}`);
          }
        }
      });
    }
    return errors;
  };

  componentDidUpdate() {
    if (this.props.submit) {
      const { errors, hasError } = FormValidator.bulkValidate(
        this.props.submit
      );
      let payload = this.state.formRegister;
      if (hasError) {
        this.props.cb2();
        if (this.oldError != JSON.stringify(errors)) {
          this.oldError = JSON.stringify(errors);
          payload = null;
          const truthyItems = Object.keys(errors).forEach(fields => {
            Object.keys(errors[fields]).forEach(key => {
              if (errors[fields][key]) {
                this.setState(prevState => ({
                  valid: {
                    ...prevState.valid,
                    [fields]: true
                  },
                  error: errors
                }));
              }
            });
          });
        }
      } else {
        this.props.cb(payload);
        if (this.props.submitField) {
          this.props.fields.forEach(element => {
            this.setState(prevState => ({
              formRegister: {
                ...prevState.formRegister,
                [element.name]:
                  element.fieldType == "select" ? element.options[0] : ""
              }
            }));
          });
        }
      }
    }
  }
  componentWillReceiveProps(newProps, prevProps) {
    
    if (newProps.formRegister !== this.props.formRegister) {
      this.setState({
        formRegister: newProps.formRegister ? newProps.formRegister : {}
      });
    }
    if(newProps.formReset===true) {
      this.props.fields.forEach(element => {
      this.resetFields(element);
      this.props.setIsReset()
          });
    }
    // console.log("_newProps.fields",newProps.fields,this.props.fields)
    this.props.fields.forEach(element => {
      // console.log("_rrrr",newProps)
      if (
        (element.name === "block" &&
          element.onBlockChange === true &&
          !this.isChanged) ||
        (element.name === "path" && !this.isChanged) 
      ) {
        this.setState(prevState => ({
          formRegister: {
            ...prevState.formRegister,
            [element.name]:
              element.fieldType == "select" ? element.options[0] : ""
          }
        }));
      }
     
    });
   
    if (
      JSON.stringify(prevProps.fields) != JSON.stringify(newProps.fields) &&
      !this.submit
    ) {
      
      this.props.fields.forEach(element => {
        if (element.value) {
          this.setState(prevState => ({
            formRegister: {
              ...prevState.formRegister,
              [element.name]:
                element.fieldType == "select"
                  ? element.default
                    ? element.options.filter(
                        option => option[element.id] == element.value.id
                      )[0]
                    : element.value
                  : element.value
            }
          }),()=>{
          });
        }
        else {
           if(this.props.fromTaskAddScreen){
            this.setState(prevState => ({
              formRegister: {
                ...prevState.formRegister,
                [element.name]: element.value
                ? element.value
                : element.fieldType == "select"
                ? element.options[0]
                : ""
              }
            }),()=>{
            });
          }
         
           
          }
      });
    }
  }
  resetFields = (element) => {
    this.setState(prevState => ({
      formRegister: {
        ...prevState.formRegister,
        [element.name]:
        element.value
        ? element.value
        : element.fieldType == "select"
        ? element.options[0]
        : ""
      }
    }));
  }
  getInputField = data => {
    return (
      <Col
        md={this.props.colmd ? this.props.colmd : 6}
        style={{ padding: this.props.padding ? 0 : null }}
      >
        <Input
         
          type={data.inputType}
          name={data.name}
          invalid={this.state.valid[data.name]}
          onChange={this.validateOnChange(data)}
          data-validate={data.valid}
          value={this.state.formRegister[data.name]}
          data-param={data.param ? data.param : ""}
          id={data.id ? data.id : null}
          disabled={data.disabled ? true : false}
         autoFocus
        />
        <span className="invalid-feedback">
          {this.hasError(data.label, data.name,data).map((err, index) => {
            return (
              <React.Fragment key={`${err}-${index}`}>
                {err}
                <br />
              </React.Fragment>
            );
          })}
        </span>
      </Col>
    );
  };
  getSelectField = data => {
    return (
      <Col
        md={this.props.colmd ? this.props.colmd : 6}
        style={{ padding: this.props.padding ? 0 : null }}
      >
        <Input
          type="select"
          name={data.name}
          onChange={this.validateOnChange(data)}
          invalid={this.state.valid[data.name]}
          disabled={data.disabled ? true : false}
        >
          {data.options &&
            data.options.map((options, index) => (
              <option
                key={options.id ? options.id : index + 1}
                id={options.id}
                selected={
                  data.default
                    ? data.isId
                      ? options[data.id] ===
                        this.state.formRegister[data.name].id
                      : options[data.default] ==
                        this.state.formRegister[data.name][data.default]
                    : options === this.state.formRegister[data.name]
                }
              >
                {data.default ? options[data.default] : options}
              </option>
            ))}
        </Input>
        <span className="invalid-feedback">
          {this.hasError(data.label, data.name,data)}
        </span>
      </Col>
    );
  };
  handleChange = (e, data) => { 
    let _isChecked = e.target.checked;
    // if(_isChecked){data.disabled=false}else{data.disabled=true}
    data.disabled=_isChecked?false:true
    this.setState(prevState => ({
      formRegister: {
        ...prevState.formRegister,
        isChecked: _isChecked
      }
    }));
  };
  render() {
    if (this.props.fields.length) {
      return this.props.fields.map((data, index) => {
        switch (data.fieldType) {
          case "input":
            if (!this.props.table) {
              return (
                <div
                  className={
                    this.props.wrapperClass ? this.props.wrapperClass : ""
                  }
                  style={{
                    width: this.props.fromBlocks ? "50%" : null,
                    paddingLeft: this.props.fromBlocks ? "40px" : null
                  }}
                  key={`a-${index + 1}`}
                >
                  <div className="form-group row " style={{ flex: "1" }}>
                    {data.isCheckbox === true && (
                      <input
                        onChange={e => this.handleChange(e, data)}
                        style={{
                          marginTop: "12px",
                          marginLeft: "15px",
                          marginRight: "10px"
                        }}
                        name="set-pin"
                        type="checkbox"
                      />
                    )}
                    <label
                      className={
                        this.props.colClassName
                          ? `col-md-${this.props.colClassName} col-form-label`
                          : data.colMd
                          ? `col-md-${data.colMd} col-form-label`
                          : "col-md-2 col-form-label"
                      }
                    >
                      {data.label}
                    </label>
                    {this.getInputField(data)}
                  </div>
                </div>
              );
            } else {
              return <td>{this.getInputField(data)}</td>;
            }
          case "select":
            if (!this.props.table) {
              return (
                <div
                  className={
                    this.props.wrapperClass ? this.props.wrapperClass : ""
                  }
                  style={{
                    width: this.props.fromBlocks ? "50%" : null,
                    paddingLeft: this.props.fromBlocks ? "40px" : null
                  }}
                  key={`b-${index + 1}`}
                >
                  <div className="form-group row " style={{ flex: "1" }}>
                    <label
                      className={
                        this.props.colClassName
                          ? `col-md-${this.props.colClassName} col-form-label`
                          : "col-md-2 col-form-label"
                      }
                    >
                      {data.label}
                    </label>
                    {this.getSelectField(data)}
                  </div>
                </div>
              );
            } else {
              return <td>{this.getSelectField(data)}</td>;
            }
          case "number":
            return (
              <div
                className="form-group row "
                style={{ flex: "1" }}
                key={index + 1}
              >
                <label
                  className={
                    this.props.colClassName
                      ? `col-md-${this.props.colClassName} col-form-label`
                      : "col-md-2 col-form-label"
                  }
                >
                  {data.label}
                </label>
                <Col md={6}>
                  <Input
                    type={data.inputType}
                    name={data.name}
                    onChange={this.validateOnChange}
                    value={this.state.formRegister[data.name]}
                  />
                  <span className="invalid-feedback">
                    {this.hasError(data.label, data.name,data)}
                  </span>
                </Col>
              </div>
            );
        
        }
      });
    } else {
      return <React.Fragment />;
    }
  }
}

export default FormContainer;
