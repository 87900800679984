import styled from 'styled-components';
import { breakPoints } from "./../../components/config/breakpoints";

export const ListStyleWrapper = styled.div`
width:100%;
height:100%;
.headerStyle {
  position: static
}
.scroll{
  overflow-y:auto;
  padding-bottom:32px;
}
.fontChange{
    font-size: 19px !important;
    color: #929292 !important;
}
.padding{
    padding-left:17px;
    cursor:pointer;
    display: inline;
    ${breakPoints.mobileScreen} {
      padding-left:0px;
  }
}
.buttonPaddingChange{
    padding-left: 24px;
     padding-right: 24px;
}
.borderChange{
   border-radius:50%;
}
.paddingOperatorsChange {
  /* padding-top: 27px !important; */
    padding-left: 30px !important;
}
.operatorChange{
     padding-top: 27px !important;
     padding-left: 30px !important;
}
.buttonChange{
    /* float: right;
    padding-bottom:15px;
    padding-right:15px; */
}
.modalPadding{
    padding-bottom: 20px;
    padding-right: 20px;
}
`
export const AddUserStyle =styled.div`
.paddingChange{
    padding-left:25px;
}
`
export const OperatorStyle = styled.div`
    /* padding-top: 25px; */
    width: ${props => props['length']>0 ? "60%" : "100%"};
.backgroundChange{
  background-color: none !important; 
}
.alignItems{
text-align:center;
}
.padding{
    padding-top:10px !important;
    padding-bottom:10px;
    padding-left:0 !important;
}
.rdt_TableRow{
    min-height:27px !important;
    max-height:45px;
   }
   .rdt_TableHeadRow{
    min-height: 32px !important;
   }
   .fontSizeChange {
    /* font-size: 8px !important;
    color: #bb7b03; */
    font-size: 14px !important;
    color: rgba(125,122,122,0.87) !important;
    cursor:pointer;
  }
.button{
    padding-top:8px
}
.rdt_TableHeadRow{
    /* background-color: #f4ecec !important; */
}
.rdt_TableCell{
    :first-of-type{
        max-width:fit-content
    }

}
.rdt_TableCol{
    :first-of-type{
        max-width:fit-content
    } 
}
.rdt_Table{
    /* div:first-child{
    padding:0;
    padding-left: 5px;
} */
}

`