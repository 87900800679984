import React from 'react';
import {Button} from 'reactstrap';
/**common component for Footer */
class CardFooterComponent extends React.Component{
    render(){
        return(
            <div className="d-flex align-items-center">
            <div className="ml-auto">
            <Button className={this.props.class?`${this.props.class} bg-croptix-color`:"bg-croptix-color"} type="submit">
                {!this.props.isNewUser ? "Update" : "Save"}
              </Button>
              <Button
                className="bg-croptix-color"
                type="button"
                onClick={() => this.props.Cancel()}
              >
               {this.props.isReset?"Reset":"Cancel"} 
              </Button>
              
            </div>
          </div>
        )
    }
};
export default CardFooterComponent;