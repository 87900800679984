import {
  API,
  SET_UP_LIST_USERS,
  SET_UP_GET_ACCOUNT,GET_CUSTOMER_SUPPORT_ACCOUNT,CS_USERS,SENSORS,OPERATORS,CYRUS_OPERATORS
} from "./../../components/services/api";
import { notificationToast, clearValue, CurrentUser, loading,modalToast,clearModalToast,setUser,setDefaultAddress } from "./Login";

// Function for list users - setup
export const getUsers = ( cb ) => {
  return dispatch => {
    dispatch(loading(true));
    return API.get(`${SET_UP_LIST_USERS}?limit=${1000}&offset=${0}`, {}, {})
      .then(res => {
        dispatch(setUser(res))
        dispatch(loading(false));
        if (cb) cb(res);
      })
      .catch(err => {
        dispatch(loading(false));
        // console.log("_err",err.res)
        if (cb) cb();
        let { message } = err.response.data;
        dispatch(notificationToast({ type: "error", message: message }));
        dispatch(clearValue());

        return err;
      });
  };
};

// function for add users-setUp/Cs

export const addUsers = ({ payload, cb,id,fromCs }) => {
  let URL = fromCs===true ? `${GET_CUSTOMER_SUPPORT_ACCOUNT}/${id}/users`:SET_UP_LIST_USERS
  return dispatch => {
    dispatch(loading(true));
    return API.post(URL, payload, {})
      .then(res => {
        dispatch(loading(false));
        let {role} = payload,
          user_id = res.id
         
          let rolePayload = {
            role
          }
        if (cb &&( (res.created_at)||fromCs)) {
         let accountId = fromCs===true?id:null;
          dispatch(addRole({rolePayload,cb,id,user_id,fromCs,accountId}))
        } 
       
         else{
          cb();
          dispatch(
            notificationToast({
              type: "warning",
              message: "User already exists"
            })
          );
          dispatch(clearValue());
        }
      })
      .catch(err => {
        // console.log("_err",err)
        if (cb) cb();
        dispatch(loading(false));

        let { message } = err.response.data;
        dispatch(notificationToast({ type: "error", message: message }));
        dispatch(clearValue());
        
      });
  };
};
// function for adding role users/cs
export const addRole = ({ rolePayload, cb,id,user_id,fromCs,fromUpdate,accountId }) => {
  let URL = fromCs===true ? `${GET_CUSTOMER_SUPPORT_ACCOUNT}/${accountId}/users/${user_id}`:`${SET_UP_GET_ACCOUNT}/users/${user_id}`
  return dispatch => {
    dispatch(loading(true));
    return API.post(URL, rolePayload, {})
      .then(res => {
        // console.log("_res", res);
        dispatch(loading(false));
        // if (cb && res.created_at) {
          cb();
          dispatch(
            notificationToast({
              type: "success",
              message: !fromUpdate?"User created successfully":"User Updated successfully"
            })
          );
          dispatch(clearValue());
       
      })
      .catch(err => {
        if (cb) cb();
        dispatch(loading(false));

        let { message } = err.response.data;
        dispatch(
          notificationToast({
            type: "error",
            message:!fromUpdate? "User created Successfully but failed to add role":"Failed to add role"
          })
        );
        dispatch(clearValue());
      });
  };
};

// function to delete users - Set Up/CS
export const deleteUser = ({ id, cb,fromCs,accountId }) => {
  let URL = fromCs?`${GET_CUSTOMER_SUPPORT_ACCOUNT}/${accountId}/users/${id}`:`${SET_UP_LIST_USERS}/${id}`
  return dispatch => {
    dispatch(loading(true));
    return API.delete(URL, {}, {})
      .then(res => {
        if (cb) cb();
        dispatch(loading(false));
        dispatch(
          notificationToast({
            type: "success",
            message: "User deleted successfully"
          })
        );
        dispatch(clearValue());
      })
      .catch(err => {
        if (cb) cb();
        dispatch(loading(false));
        let { message } = err.response.data;
        dispatch(notificationToast({ type: "error", message: message }));
        dispatch(clearValue());
      });
  };
};

// function for update User - Set Up/CS
export const updateUser = ({ payload, id, cb, flag,fromCs,accountId }) => {
  return dispatch => {
    dispatch(loading(true));
    let URL = fromCs===true?`${CS_USERS}/${id}`:`${SET_UP_LIST_USERS}/${id}`
    return API.patch(URL, payload, {})
      .then(res => {
        dispatch(loading(false));
        if (cb) cb();
        let {role} = payload,
        user_id = id,
        fromUpdate = true
       
        let rolePayload = {
          role
        }
      // if (cb &&( (res.created_at)||fromCs)) {
       
        dispatch(addRole({rolePayload,cb,id,user_id,fromCs,fromUpdate,accountId}))
      // } 
        if (flag) {
          payload.id = id;
          dispatch(CurrentUser(payload));
        }
        // dispatch(
        //   notificationToast({
        //     type: "success",
        //     message: "User Updated successfully"
        //   })
        // );
        // dispatch(clearValue());
      })
      .catch(err => {
        if (cb) cb();
        dispatch(loading(false));
        let { message } = err.response.data;
        if (err.response.data.status === 500) {
          dispatch(
            notificationToast({
              type: "error",
              message: "Email Already exists"
            })
          );
          dispatch(clearValue());
        } else {
          dispatch(notificationToast({ type: "error", message: message }));
          dispatch(clearValue());
        }
      });
  };
};

// function for get Account -> SetUp->Account

export const getSetUpAccount = ( cb ) => {
  return dispatch => {
    dispatch(loading(true));
    return API.get(SET_UP_GET_ACCOUNT, {}, {})
      .then(res => {
        dispatch(loading(false));
        
        let _coord = res.addresses&&res.addresses.length>0&&res.addresses[0].location?res.addresses[0].location: {lat:0, lng: 0} ;
        let payload = {
          coordinates:_coord
        }
        dispatch(setDefaultAddress(payload))
        if (cb) cb(res);
      })
      .catch(err => {
        console.log("_err",err)
        dispatch(loading(false));
        // console.log("_err",err.res)
        if (cb) cb();
        // let { message } = err.response.data;
        // dispatch(notificationToast({ type: "error", message: message }));
        // dispatch(clearValue());

        return err;
      });
  };
};

// function for get operator under particular userId

export const getOperator = ({  callback,fromCs,account_id }) => {
  let URL = fromCs?`${CYRUS_OPERATORS}?account_id=${account_id}&&limit=${1000}&&offset=${0}`:`${OPERATORS}?limit=${1000}&&offset=${0}`;
  
  return dispatch => {
    dispatch(loading(true))
    return API.get(URL, {}, {})
      .then(res => {
        if (callback) callback(res);
        dispatch(loading(false));
        
      })
      .catch(err => {
        
        if (callback) callback();
        let { message } = err.response.data;
        dispatch(notificationToast({ type: "error", message: message }));
        dispatch(clearValue());

        return err;
      });
  };
};


// function for add operators under  a given user

export const postOperators = ({ payload,id, callback,fromCs }) => {
  let URL=fromCs===true?CYRUS_OPERATORS:OPERATORS
  return dispatch => {
    return API.post(URL, payload, {})
      .then(res => {
       
        if (callback && res.created_at) {
          callback(res);
          dispatch(
            notificationToast({
              type: "success",
              message: "Operator created successfully"
            })
          );
          dispatch(clearValue());
        } else {
          callback();
          dispatch(
            notificationToast({
              type: "warning",
              message: "Operator already exists"
            })
          );
          dispatch(clearValue());
        }
      })
      .catch(err => {
        if (callback) callback();

        let { message } = err.response.data;
        dispatch(modalToast({ type: "error", message: message }));
        dispatch(clearValue());
      });
  };
};


// function to update operators under particular user

export const updateOperator = ({ payload, id,operator_id, callback,fromCs }) => {
  return dispatch => {
    let URL= fromCs===true?`${CYRUS_OPERATORS}/${operator_id}`:`${OPERATORS}/${operator_id}`
    return API.patch(URL, payload, {})
      .then(res => {
        if (callback) callback();
        
        dispatch(
          notificationToast({
            type: "success",
            message: "Operator Updated successfully"
          })
        );
        dispatch(clearValue());
      })
      .catch(err => {
        console.log("_err", err);
        if (callback) callback();
        let { message } = err.response.data;
        if (err.response.data.status === 500) {
          dispatch(
            notificationToast({
              type: "error",
              message: "Failed to update operator"
            })
          );
          dispatch(clearValue());
        } else {
          dispatch(notificationToast({ type: "error", message: message }));
          dispatch(clearValue());
        }
      });
  };
};

// function for delete operator under particular user

export const deleteOperator = ({ id,operator_id, callback,fromCs }) => {
  let URL= fromCs===true?`${CS_USERS}`:`${OPERATORS}/${operator_id}`
  
  return dispatch => {
    return API.delete(URL, {}, {})
      .then(res => {
        if (callback) callback();
        dispatch(
          notificationToast({
            type: "success",
            message: "Operator deleted successfully"
          })
        );
        dispatch(clearValue());
      })
      .catch(err => {
        if (callback) callback();
        let { message } = err.response.data;
        dispatch(notificationToast({ type: "error", message: message }));
        dispatch(clearValue());
      });
  };
};

// Function for read scanners(ie sensors)

export const getScanners = (callback) => {
  return dispatch => {
    dispatch(loading(true));
    return API.get(`${SENSORS}?limit=${1000}&offset=${0}`, {}, {})
      .then(res => {
      
        dispatch(loading(false));
        if (callback) callback(res);
      })
      .catch(err => {
        dispatch(loading(false));
        // console.log("_err",err.res)
        if (callback) callback();
        let { message } = err.response.data;
        dispatch(notificationToast({ type: "error", message: message }));
        dispatch(clearValue());

        return err;
      });
  };
}