import styled from "styled-components";

export const FooterStyle = styled.div`
  .alignItems {
    margin-left: 0 !important;
    z-index: 9999 !important;
    .padding {
      padding-right: 10px;
    }
    .postion {
      float: right;
    }
    .color {
      color: black;
    }
  }
  .position {
    position: fixed !important;
  }
`;
