import dateFnsFormat from 'date-fns/format';
import dateFnsFormatISO from 'date-fns/formatISO';
import dateFnsParse from 'date-fns/parse';
import dateFnsParseISO from 'date-fns/parseISO';
import configureStore from "../store/store";
import {
  LOGOUT_REDIRECTION_URL,
  LOGOUT_API
} from "../components/services/api";
import jwtDecode from 'jwt-decode';

// Citrus health state icons
import CitrusColorRed from "../assets/citrus_color_red.svg";
import CitrusColorGreen from "../assets/citrus_color_green.svg";
import CitrusColorBlack from "../assets/citrus_color_black.svg";
import CitrusColorGray from "../assets/citrus_color_gray.svg";
import CitrusColorWhite from "../assets/citrus_color_white.svg";
import CitrusColorOrange from "../assets/citrus_color_orange.svg";

import greenTree from "../assets/GreenTree.png";
import redTree from "../assets/redTreeRound.png";
import whiteTree from "../assets/whiteTreeRound.png";
import blackTree from "../assets/blackTreeRound.png";
import grayTree from "../assets/grayTreeRound.png";
import orangeTree from "../assets/orangeTreeRound.png";

const store = configureStore();
const DEBUG_LOGGING = process.env.REACT_APP_DEBUG_LOGGING || false;

export const debugLog = (...args) => {
  if (DEBUG_LOGGING) {
    console.log(...args)
  }
}

// To get value from cookie
export const getCookieValue = name => {
  let regEx = new RegExp(name + "=([^;]+)");
  let value = regEx.exec(document.cookie);
  return value != null ? value[1] : null;
};

// to  set Local Storage
export const setLocalStorage = (key, value) => {
  try {
    localStorage.setItem(key, value);
  } catch (e) {
    return;
  }
};

// to  get Storage value
export const getLocalStorage = key => {
  try {
    return localStorage.getItem(key);
  } catch (e) {
    return;
  }
};

// function for removing localstorage
export const removeLocalStorage = (key) => {
  try {
    localStorage.removeItem(key);
  } catch (e) {
    return;
  }
};

// fallback mechanism  for store value(if value doesn't exist in store fallback to local storage)
export const getPersistValue = (reducer, stateKey, localKey) => {
  try {
    const storeData = store.getState();
    return storeData[reducer][stateKey]
      ? storeData[reducer][stateKey]
      : getLocalStorage(localKey);
  } catch (e) {
    return null;
  }
};

// delete all cookies
export const deleteCookie = () => {
  
    document.cookie = "auth0.is.authenticated" + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
 
};

// logout url from auth0
export const logOutFromAuth0 = () => {
  deleteCookie();
  clearStorage();
  window.location.assign(
    `${LOGOUT_API}?returnTo=${LOGOUT_REDIRECTION_URL}&client_id=${process.env.REACT_APP_AUTH0_CLIENT_ID}`
  );
};

// To clear storage
export const clearStorage = () => localStorage.clear();

export const mailToLink = () => {
  return  window.location.href = "mailto:support@croptix.solutions"; 
}

export const logOut = () => {
  deleteCookie();
  clearStorage();
  window.location.replace('/');
}

/**
 * Formats a Date object into an ISO date string.
 * @param   {Date}    date    Date object to be formatted
 * @return  {string}          ISO date string
 */
export const formatDateISO = (date) => {
  return dateFnsFormatISO(date, { representation: 'date' })
}

/**
 * Formats an ISO date string into a date string in the user-facing format.
 * @param   {string}  dateStr ISO date string to be formatted
 * @return  {string}          User-facing date string
 */
export const formatISODateString = (dateStr) => {
  return dateFnsFormat(dateFnsParse(dateStr.substring(0, 10), "yyyy-MM-dd", new Date()), displayDateFormat)
}

export const displayDateFormat = "MM/dd/yyyy"
export const displayTimeFormat = "MM/dd/yyyy, h:mm a"

export const getTokenInformation = (token) => {
 let tokenInformation = jwtDecode(token);
 return tokenInformation['http://croptix.solutions/app_metadata'];

}

export const getIdFromUrl = () => {
  return window.location.search.split("=")[1];
}
export const getSelectedAccountFromStorage = (id) => {
  let selectedAccount = JSON.parse(getLocalStorage(`selected_account_${id}`));
  return selectedAccount;
}


// function to convert array of Objects to array of arrays

export const getLatLngArray = (arr,fromPath) => {
  let _latLngArr = arr.map((obj) => {
    return obj
    // return Object.keys(obj).map((key) =>{ 
    //   console.log("_obj",obj,obj[key])
    //   return obj[key];
    });
  // });
  return fromPath?[..._latLngArr]:[..._latLngArr,_latLngArr[0]];
}

// function for polygon event for checking 

export const PolygonEvent = (cordinates) => {
  return new window.google.maps.Polygon({
    paths: cordinates
  });
}

export const mapValidation = (mapCoordinates,polygon) => {
  return (window.google.maps.geometry.poly.containsLocation(new window.google.maps.LatLng(mapCoordinates.lat,mapCoordinates.lng), polygon))
}
export const defaultLocation = () => {
  return  {lng: -117.8357194 , lat: 33.7906731 }
  };

  export const savePdf =(value,name) => {
    let newBlob = new Blob([value], { type: "application/pdf" });
    const data = window.URL.createObjectURL(newBlob);
    let link = document.createElement("a");
    link.href = data;
    link.download = name;
    link.click();
  };

  export const saveJSON =(value,name,type) => {
    let data = type + encodeURIComponent(JSON.stringify(value)),
     a = document.createElement('a');
    a.href = 'data:' + data;
    a.download = name;
    a.innerHTML = 'download JSON';
    a.click();
  };
  
  export const getIcon = ({name}) => {
    if(name.includes('.pdf')){
      return "fa-2x mr-2 far fa-file-pdf padding pdfColor"
    }
    else if(name.includes('.ppt')||name.includes('.pptx')){
      return "fa-2x mr-2 far fa-file-powerpoint padding pptColor"
    }
    else if(name.includes('.doc')||name.includes('.docx')){
      return "fa-2x mr-2 far fa-file-word padding"
    }
    else if(name.includes('png')||name.includes('jpg')||name.includes("jpeg")){
      return "fa-2x mr-2 far fa-file-image padding imgColor"
    }
    else if(name.includes('xlx')||name.includes('xlsx')){
      return 'fa-2x mr-2 far fa-file-excel padding xlsxColor'
    }
    else {
      return "fa-2x mr-2 far fa-file-alt padding fileColor"
    }
  };

  export const getResponseType = _name => {
    if (_name.includes(".txt")) {
      return "text";
    } else if (_name.includes(".json")) {
      return "json";
    } else {
      return "arraybuffer";
    }
  };

  // Takes a list of blocks from the API, drops hidden blocks, adds `name` and
  // `value` fields, and sorts the list alphabetically by name.
  export const processBlocksFromAPI = (blockList) => {
    if (!blockList) {
      console.warn("processBlocksFromAPI: blockList is falsy", blockList)
      return []
    }

    // Exclude hidden blocks, and add in React fields.
    let _blocks = blockList.map(item => {
        if (!item.hidden) {
          let obj = {
            ...item,
            name: item.client_name ? item.client_name : "-",
            value: item.id?item.id:"-",
            label: item.client_name ? item.client_name : "-"
          };
          return obj
        }
      })
      .filter(item=>item);

    return sortBlocks(_blocks, 'name');
  };

  // Returns a descriptive string for a result's health state.
  export const getHealthStateLabel = (health_state) => {
    switch (health_state) {
      case -1:
        return "Awaiting Analysis";
      case 0:
        return "Healthy";
      case 1:
        return "HLB+";
      case 2:
        return "Skipped"
      case 3:
        return "Borderline HLB"
      default:
        return "Healthy";
    }
  }

  // Returns a colored tree icon (dot) for display on a map based on a result's
  // health state.
  export const getHealthStateTreeIcon = (health_state) => {
    switch (health_state) {
      case -1:
        return grayTree;
      case 0:
        return greenTree;
      case 1:
        return redTree;
      case 2:
        return whiteTree;
      case 3:
        return orangeTree;
      default:
        return greenTree;
    }
  }

  // Returns a colored fruit icon for display in the details pop-up based on a
  // result's health state.
  export const getHealthStateCitrusIcon = (health_state) => {
    switch (health_state) {
      case -1:
        return CitrusColorGray;
      case 0:
        return CitrusColorGreen;
      case 1:
        return CitrusColorRed;
      case 2:
        return CitrusColorWhite;
      case 3:
        return CitrusColorOrange;
      default:
        return CitrusColorGreen;
    }
  }

  // Returns a list of objects used to generate a legend for health state colors
  // on a map.
  export const getHealthStateIconLegend = () => {
    return [
      {
        name: getHealthStateLabel(-1),
        icon: getHealthStateTreeIcon(-1)
      },
      {
        name: getHealthStateLabel(0),
        icon: getHealthStateTreeIcon(0)
      },
      {
        name: getHealthStateLabel(1),
        icon: getHealthStateTreeIcon(1)
      },
      {
        name: getHealthStateLabel(2),
        icon: getHealthStateTreeIcon(2)
      },
      {
        name: getHealthStateLabel(3),
        icon: getHealthStateTreeIcon(3)
      }
    ];
  }

  export const setCookie = (cname, cvalue, exdays)=> {
    let d = new Date();
    d.setTime(d.getTime() + (exdays*24*60*60*1000));
    let expires = "expires="+ d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }

  // map menu items based on our order
  export const  mapMenuOrder  = (array, order, key)  => {
   array.sort(  (a, b) =>{
      let _a = a[key],
      _b = b[key];
      
      if (order.indexOf(_a) > order.indexOf(_b)) {
        return 1;
      } else {
        return -1;
      }
      
    });
    
    return array;
  };

// check whether a marker/polyline is inside a polygon

export const IsInPolygon = (array,latLngArr) => {
  let _polygon = new window.google.maps.Polygon({
    paths: array
  });
  let flag;
   latLngArr &&
  latLngArr.map(item => {
    let point = window.google.maps.geometry.poly.containsLocation(
      new window.google.maps.LatLng(item.lat, item.lng),
      _polygon
    );
   flag = !point? false:true;
  });
  return flag
}


// Alphanumeric sorting
export const sortBlocks = (blocks, parameter) => {
  return blocks.sort( (a, b) => {
    a[parameter].localeCompare(b[parameter], 'en', { numeric: true })
  });
}

// For percentage precission to 2 digits

export const confidencePercentage = (num)=>(num*100).toFixed(2)
export const weatherIcons = (value) => {
  switch(value){
    case 'skc':
    return "wi wi-night-clear";
    case 'few':
    return "wi wi-day-cloudy";
    case "bkn":
    return "wi wi-day-cloudy";
    case "ovc":
    return "wi wi-day-sunny-overcast"
    case "wind_skc":
    return "wi wi-day-light-wind"
    case "wind_bkn":
    return "wi wi-day-cloudy-windy"
    case "wind_ovc":
    return"wi wi-day-light-wind"
    case "snow":
    return "wi wi-day-snow"
    case "rain_snow":
    return "wi wi-day-snow-wind"
    case "rain_sleet":
    return "wi wi-day-sleet"
    case "snow_sleet":
    return "wi wi-day-sleet"
    case 'fzra':
    return "wi wi-day-rain-mix";
    case 'rain_fzra':
    return "wi wi-day-rain-mix";
    case "rain_showers":
    return "wi wi-day-rain";
    case "rain_showers_hi":
    return "wi wi-day-rain"
    case "tsra_hi":
    return "wi wi-day-thunderstorm"
    case "tsra":
    return "wi wi-day-thunderstorm"
    case "tsra_sct":
    return "wi wi-day-thunderstorm"
    case "tornado":
    return "wi wi-tornado"
    case "hurricane":
    return "wi wi-hurricane"
    case "dust":
    return "wi wi dust"
    case "smoke":
    return "wi wi-smoke"
    case  "haze":
    return "wi wi-day-haze"
    case "hot":
    return "wi wi-hot"
    case "cold":
    return "wi wi-snowflake-cold"
    case "smoke":
    return "wi wi-smoke"
    case "fog":
    return "wi wi-day-fog"
   default:
   return "wi wi-day-sunny";
  }
};


// remove duplicates from array objects

export const removeDuplicates = (array, property)  => {
  return array.filter((obj, pos, arr) => {
      return arr.map(mapObj => mapObj[property]).indexOf(obj[property]) === pos;
  }); 
}

// Utility function for calculator function in gmaps marker cluster
export const calculator = (markers, numStyles) => {
  let index = 0,
   count = markers.length,
   dv = count;
  let _markers =
    markers &&
    markers.map(item => {
      if (item.icon.fillColor === "red") {
        return (index = 2);
      } else {
        return (index = 1);
      }
    });
  index = Math.min(index, numStyles);
  return {
    text: count,
    index: index,
    textColor: "white"
  };
};

// utility function for onClusterClick 

export const onMarkerClustererClick = () => markerClusterer => {
  const clickedMarkers = markerClusterer.getMarkers();
};

// utility for selectedTree Location

export const selectedLocationHighlight = (arr,Id,isHighlight) => {
 return arr&&arr.map(item=>{
    if(item.id === Id){
    return{
      ...item,
      highlight:isHighlight
    }
    }
    else{
      return{
        ...item
      }
    }
  });
};

// utility function for cluster size

export const getClusterSize = (TreeMarker) => {
  if(TreeMarker.length>=4000&&TreeMarker.length<10000){
    return 4000
  }
 else if(TreeMarker.length>=10000){
   return 10000
 }
 else if(TreeMarker.length<4000){
   return TreeMarker.length
 }
};
// default location for drawing manager
export const location ={lat:38.908133, lng: -77.047119}
