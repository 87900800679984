import { SET_MENU_ITEMS, UPDATE_MENU_ITEMS, SET_POP_OVER } from "../actions/setMenuItems"

const initialState = {
  menuItems: [],
  updated: false,
  oldMenuItems: [],
  enableEdit: false,
  popover: false
};

const setMenuItems = (state = initialState, action) => {
  switch (action.type) {
    case SET_MENU_ITEMS:
      return {
        ...state,
        menuItems: action.menuItems,
      };
    case UPDATE_MENU_ITEMS:
      return {
        ...state,
        oldMenuItems: action.menuItems,
        updated: true
      }
    case 'SET_FALSE':
      return {
        ...state,
        updated: false
      }
    case 'OPEN_IN_ANOTHER_TAB':
      return {
        ...state,
        isOpenedInModal: true,
        updated: false,
        enableEdit: false
      }
    case 'ENABLE_EDIT':
      return {
        ...state,
        enableEdit: true
      }
    case SET_POP_OVER: 
      return {
        ...state,
        popover: true
      }
    default:
      return state;
  }
};

export default setMenuItems;