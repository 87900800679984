import styled from "styled-components";
import { breakPoints } from "./../config/breakpoints";

export const DashboardStyle = styled.div`
  .paddingBottom {
    padding-bottom: 0;
  }
  .dashboard-container {
    margin-bottom: 20px;
  }
  .border {
    border-bottom: 1px solid lightgray;
    font-size: 17px;
    font-weight: bold;
  }
  .rdt_TableCell:first-child {
    min-width: 20px !important;
  }
  .loaderWrapperStyle {
    position: static;
  }
  .rdt_TableBody {
    max-height: 200px;
    overflow-y: auto;
    ::-webkit-scrollbar {
      width: 7px;
    }
    .rdt_TableHeadRow {
      min-height: 40px;
    }
    /* Track */
    ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px grey;
      border-radius: 10px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: lightgrey;
      border-radius: 10px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #bb7b03;
    }
  }
  .rdt_TableFooter {
    min-height: 0px;
    padding: 0;
  }
  .rdt_TableFooter div button {
    height: 24px;
    padding: 0px;
    margin: 0px;
  }
`;

export const Wrapper = styled.div`
  /*padding-top: 20px;*/
  /*.padding {
    padding-top: 10px !important;
  }*/
  .fontSizeChange {
    font-size: 10px;
    color: rgba(125, 122, 122, 0.87) !important;
    cursor: pointer;
  }
  .height {
    /* height:calc(100vh - 600px) !important; */
    height: 500px !important;
    /* padding-top:10 */
  }
  .customScroll {
    div:first-child {
      ::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #f5f5f5;
        /* background-color:red */
      }

      ::-webkit-scrollbar {
        height: 5px;
        background-color: #f5f5f5;
      }

      ::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-image: -webkit-gradient(
          linear,
          left bottom,
          left top,
          color-stop(0.44, #9e9e9e61),
          color-stop(0.72, #9e9e9e9e),
          color-stop(0.86, #9e9e9ec7)
        );
      }
    }
  }
  .card-header {
    background-color: #f9a606b3 !important;
    color: white !important;
    padding: 7px 16px;
  }
`;
export const NotificationWrapper = styled.div`
  /* border:1px solid lightgray; */
  /* margin-left:20px;
margin-right:20px; */
  /* margin-top:10px; */
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 10px;
  .buttonPaddingChange {
    padding-left: 24px;
    padding-right: 24px;
  }
  .marginBottom {
    margin-bottom: 12px;
    float: right;
    /* margin-right: 10px; */
  }

  .flexDisplay {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .card {
    margin-top: 10px;
    margin-bottom: 10px;
    /* box-shadow: 2px 4px lightgray; */
    margin-left: 15px;
    margin-right: 15px;
  }
  .border {
    border: 1px solid lightgray;
    /*padding-top: 10px;
    padding-bottom: 10px;*/
    max-height: 700px;
    overflow-y: auto;
    margin-bottom: 10px;
    ::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: #f5f5f5;
    }

    ::-webkit-scrollbar {
      width: 5px;
      background-color: #f5f5f5;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 5px;
      background-image: -webkit-gradient(
        linear,
        left bottom,
        left top,
        color-stop(0.44, #9e9e9e61),
        color-stop(0.72, #9e9e9e9e),
        color-stop(0.86, #9e9e9ec7)
      );
    }
  }
`;

export const WeatherStyle = styled.div`
  padding: 10px 10px 10px 10px;
  width: 100%;
  overflow: auto;
  height: 100%;
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #f5f5f5;
  }

  ::-webkit-scrollbar {
    width: 5px;
    background-color: #f5f5f5;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-image: -webkit-gradient(
      linear,
      left bottom,
      left top,
      color-stop(0.44, #9e9e9e61),
      color-stop(0.72, #9e9e9e9e),
      color-stop(0.86, #9e9e9ec7)
    );
  }

  .paddingHeader {
    padding: 10px 0px 0px 10px;
  }
  .temp {
    font-size: 17px;
    padding-left: 10px;
  }
  .subDiv {
    /* padding: 18px; */
    padding:3px;
    font-size: 17px;
  }
  .flexDisplay {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
  }
  .norecordsWrapper {
    height: 100%;
    width: 100%;
    margin: 0 auto;
    display: block;
    /* vertical-align: middle; */
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .h4Style {
    margin: 0;
    font-size: 17px;
    margin-bottom: 2px;
  }

  .pStyle {
    margin: 0;
    font-size: 15px;
    padding-top: 2px;
    padding-bottom: 4px;
    font-style: italic;
    padding-right: 15px;
  }
  .chartDiv{
    max-height: 200px;
    margin-bottom: 10px;
    
    @media (max-width: 900px) {
      max-height: fit-content !important;
    min-height: 210px !important;
    margin-bottom: 0 !important;
    padding-top: 15px !important;
    }
    ${breakPoints.uptoSmallMedium} {
      max-height: fit-content !important;
    min-height: 210px !important;
    margin-bottom: 0 !important;
    padding-top: 15px !important;
      }
  }
`;
