export const DELETED_USER = "User Deleted successfully";
export const TOAST_SUCCESS="success";
export const DEFAULT_TOAST_POSITION="top-right";
export const DELETE_USER_CONFIRMATION = "Are you sure you want to remove the user?";
export const HIDE_BLOCK ="Are you sure you want to hide this Block?"
export const DELETE_BLOCK ="Are you sure you want to delete this Block?"
export const DELETE_OPERATOR_CONFIRMATION = "Are you sure you want to remove the operator?"
export const DELETE_ADDRESS = "Are you sure you want to remove the address?"
export const TOAST_WARNING = "warning";
export const OK = "Ok";
export const CANCEL = "Cancel";
export const CROPTIX_BUTTON_COLOR = "bg-croptix-color";
export const ALERT_ANIMATION_CLASS = "alertAnimationChange";
export const TOAST_ANIMATION_CLASS = "toastAnimationChange";
export const WARNING ="warning";
export const LOG_OUT = "Are you sure that you want to logout?";
export const BLOCKS = 'Blocks';
export const ADD_BLOCKS ="Add Blocks";
export const EDIT_BLOCKS ="Edit Blocks";
export const STRUCTURE_ERROR_MESSAGE = " Please draw a  closed polygon structure";
export const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_MAP_API_KEY;
export const NO_USER_ACCOUNT = "You are not a user on any accounts";
export const ADD_OPERATORS ="Add Operators";
export const EDIT_OPERATORS ="Edit Operators";
export const ADD_USER = "Add User";
export const EDIT_USER="Edit User";
export const NO_RECORDS_FOUND = "No records found";
export const BLOCK_DETAILS = "Please add atleast one name for this block";
export const PATHS = "Path";
export const HIDE_TASK_TEMPLATE = "Are you sure you want to delete this Task Template?";
export const ADD_TASK_TEMPLATE = "Add Task Templates";
export const EDIT_TASK_TEMPLATE = "Edit Task Templates";

export const PATH_BLOCK_VALIDATION_ERROR = "The drawn path is not inside the block";
export const PATH_ERROR_MESSAGE ="Please  draw a path";
export const TASK_TEMPLATE = "Task Template";
export const TASK = "Task";
export const ADD_TASK = "Add Task";
export const EDIT_TASK ="Edit Task";
export const NO_PATH_ERROR ="Action denied.Please select a block that has a path";
export const DELETE_TASK ="Are you sure you want to remove this task?"
export const REPORTS = "Reports";
export const UPLOAD_REPORT = "Upload Report";
export const UPLOAD_ERROR_MESSAGE = "Please upload the report";
export const DELETE_REPORT = "Are you sure you want to delete the report?";
export const DASHBOARD = "Dashboard";
export const BLOCK_ERROR ="Action denied.Please add a block to continue further";
export const TEMPLATE_ERROR  = "Action Denied.Please select a template that has path";
export const MAPPEDRESULTS = "Mapped Results";
export const ORANGE_VECTOR_SYMBOL = "M4.98819986,0 L5.09055556,2.65722222 L5.1101945,2.6575276 C5.19092961,2.65868674 5.270477,2.66099961 5.34884644,2.66444463 C5.37389819,2.60884673 5.40135836,2.54906402 5.43104383,2.48541938 C6.02505153,1.21188694 7.03615967,0.470784506 8.05548775,0.470784506 C9.02336633,0.470784506 9.43418275,0.575794011 8.05548775,1.63343641 C7.72908008,1.88383449 7.04130553,2.24641283 5.99216411,2.72117141 C8.64406042,3.08132589 9.72344292,4.90524519 9.72344292,7.10110133 C9.72344292,9.55570022 7.31632036,11.6507975 4.86172147,11.6507975 C2.40712258,11.6507975 0,9.55570022 0,7.10110133 C0,4.84256636 1.35604235,2.9775045 4.21076667,2.6939665 C3.80108258,2.48987387 3.26054319,2.19338861 2.77859158,1.87142583 C1.74489793,1.1808775 0.967334517,0.3925866 2.04020182,0.565049914 C3.62575375,0.819927228 4.45097872,2.06704291 4.74003542,2.66038075 C4.79015919,2.65884206 4.84072531,2.65778834 4.89171572,2.65720453 L4.47123208,0 L4.98819986,0 Z"
export const USERS = "Users";
export const BLOCK_TYPE_ERROR ="You must remove the name of this type before adding a new one.";
export const SELECTED_BLOCK_BORDER =" #BB7A05";
export const  SELECTED_BLOCK_FILL_COLOR = "#F9A606";
export const SENSORS = "Sensors";
export const NOTIFICATION = "Notification";
export const TREES = "Tree Locations";
export const VIEW_ONLY = "View Only";
export const ADD_INDIVIDUAL_TREE ="Add Individual Tree";
export const ADD_BULK_TREE = "Add Bulk Trees";
export const REMOVE_TREES = "Remove Trees";
export const TREE_MARKER_VALIDATION = "The drawn tree is not inside the specified block.";
 export const ADD_TREE_ERROR_MESSAGE ="Please add Tree.";
 export const TREE_SVG = "M6.99588016,0 L12,5 L8.997,5 L13,9 L9.997,9 L14,13 L9,13 L9,16 L5,16 L5,13 L0,13 L3.997,9 L1,9 L4.997,5 L2,5 L6.99588016,0 Z";
 export const DELETE_TREE_CONFIRMATION = "Are you sure you want to remove the tree?";
 export const EDIT_TREES="Edit Trees";
 export const POLYLINE_PURPLE_COLOR = "#CC8899";
 export const POLYLINE_GREY_COLOR = "#B2B2B2";
//  export const Z_INDEX = 9999999999999;
export const Z_INDEX = 100;
export const POLYLINE_COLOR = "#54EDA8"; /* Eucalyptus color */
export const POLYLINE_HIGLIGHT_COLOR = "#FF5581"; /* Brink pink color */