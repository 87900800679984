import { SET_NOTIFICATION } from "../actions/DashboardAction";

const initialSettings = {
 notification:[]
};

const CustomerSupport = (state = initialSettings, action) => {
  switch (action.type) {
    case SET_NOTIFICATION:
      return {
        ...state,
        notification:action.notification
      };
   
    default:
      return state;
  }
};

export default CustomerSupport;