import Loader from "react-loader-spinner";
import React from "react";
import { LoaderWrapper } from './Loader.style';
class LoaderComponent extends React.Component {
  render() {
    return (
      <LoaderWrapper className={this.props.loaderWrapperStyle} 
      style={{position:this.props.fromModal ===true?"relative":""}}
      >
        <Loader type="TailSpin" color="#F9A604" height={this.props.height?parseInt(this.props.height):100} width={this.props.width?Number(this.props.width):100}/>
        </LoaderWrapper>
    );
  }
}
export default LoaderComponent;
