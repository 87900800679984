import React, { Suspense, lazy } from "react";
import { withRouter, Switch, Route, Redirect } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";


/* loader component for Suspense*/
import PageLoader from "./components/Common/PageLoader";

import Base from "./components/Layout/Base";
import BasePage from "./components/Layout/BasePage";
// import BaseHorizontal from './components/Layout/BaseHorizontal';

/* Used to render a lazy component with react-router */
const waitFor = Tag => props => <Tag {...props} />;
const Login = lazy(() => import('./pages/Login/LoginContainer'));
const NoRecordsComponent =lazy(()=>import('./components/SingleView/PageNotFound'))
const ListUser = lazy(() => import("./components/SetUp/ListContainer"));
const AddUser = lazy(()=>import ("./components/SetUp/AddUser"))
const CustomerSupport = lazy(()=>import("./components/CustomerSupport/CustomerSupportContainer"));
const CsAccountInformation = lazy(()=>import("./components/CustomerSupport/AccountInformation"));
const Blocks = lazy(() => import('./components/Blocks/BlocksContainer'));
const TaskTemplate = lazy(()=>import('./components/TaskTemplate/TaskTemplate/TaskTemplateContainer'));
const Task = lazy(()=>import('./components/TaskTemplate/Task/TaskContainer'));
const Reports = lazy(()=>import('./components/Reports/ReportContainer'));
const DashboardComponent = lazy(()=>import('./components/Dashboard/DashboardContainer'));
const MappedResults = lazy(()=>import('./components/MappedResults/MappedResultsContainer'));
const CsUsers = lazy(()=>import('./components/CustomerSupport/Users/UserContainer'));
const Scanners = lazy(()=>import('./components/SetUp/Scanner/ScannerContainer'));
const Notification = lazy(()=>import('./components/SetUp/Notification/NotificationContainer'));
const Trees = lazy(()=>import('./components/CustomerSupport/Trees/TreesContainer'))
const Operators = lazy(()=>import("./components/SetUp/Operator/OperatorContainer"));
// List of routes that uses the page layout
// listed here to Switch between layouts
// depending on the current pathname
const listofPages = [
  '/',
  '/login',
];

const Routes = props => {
  let { location } = props;

  const currentKey = location.pathname.split("/")[1] || "/";
  const timeout = { enter: 500, exit: 500 };
  const animationName = "rag-fadeIn";

  if (listofPages.indexOf(location.pathname) > -1) {
    return (
      // Page Layout component wrapper
      <BasePage>
        <Suspense fallback={<PageLoader />}>
          <Switch location={location}>
          <Route path="/" component={waitFor(Login)}/>
          <Route path="/login" component={waitFor(Login)}/>
          
            {/* See full project for reference */}
          </Switch>
        </Suspense>
      </BasePage>
    );
  } else {
    return (
      // Layout component wrapper
      // Use <BaseHorizontal> to change layout
      <Base>
      {/* {props.loading && <PageLoader />} */}
     
        <TransitionGroup>
          <CSSTransition
            key={currentKey}
            timeout={timeout}
            classNames={animationName}
            exit={false}
          >
            <div>
              <Suspense fallback={<PageLoader />}>
                <Switch location={location}>
                  <Route path="/404-page" component={waitFor(NoRecordsComponent)} />
                  <Route path="/setup-list" component={waitFor(ListUser)} />
                  <Route path="/setup-add" component={waitFor(AddUser)} />
                  <Route path="/customer-support" component={waitFor(CustomerSupport)}/>
                  <Route path="/cs-edit" component={waitFor(CsAccountInformation)}/>
                  <Route path="/account" component={waitFor(CsAccountInformation)}/>
                  <Route path="/blocks" component={waitFor(Blocks)} />
                  <Route path="/task-template" component={waitFor(TaskTemplate)}/>
                  <Route path="/task" component={waitFor(Task)}/>
                  <Route path="/reports" component={waitFor(Reports)}/>
                  <Route path="/cs-upload" component={waitFor(Reports)}/>
                  <Route path="/dashboard" component={waitFor(DashboardComponent)}/>
                  <Route path="/mapped-result" component={waitFor(MappedResults)}/>
                  <Route path="/cs-users" component={waitFor(CsUsers)}/>
                  <Route path="/setUp-scanners" component={waitFor(Scanners)}/>
                  <Route path="/setUp-notification" component={waitFor(Notification)}/>
                  <Route path="/cs-tree" component={waitFor(Trees)}/>
                  <Route path="/operator" component={waitFor(Operators)}/>
                  <Redirect to="/404-page" />
                </Switch>
              </Suspense>
            </div>
          </CSSTransition>
        </TransitionGroup>
      </Base>
    );
  }
};

export default withRouter(Routes);









