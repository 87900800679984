import React, { Component } from "react";
import { Row, Col, CardBody, CardFooter, Button } from "reactstrap";
import { AddUserStyle } from "./SetUp.styles";
import { options } from "./GetRole";
import { AddUserDataModel } from './UserDataModel';
import FormContainer from './../FormContainer/FormContainer';
import CardFooterComponent from './../config/CardFooter';

class AddUser extends Component {
  
  role = [{
    fieldType: "select",
    inputType: "text",
    name: "role",
    label: "Role",
    options: options(this.props) ,
    value:null,
    default: "name",
  }]
  state = {
    view: null,
    formRegister:{},
    DataModel:this.props.fromHeader ? AddUserDataModel : [...AddUserDataModel, ...this.role]
  };
  componentDidMount = () => {
    if (!this.props.isNewUser) {
      
     let { User } = this.props;
      let _options = options(this.props);
      let selectedRole = _options.filter(item=>item.name===this.props.User.role)[0];
      if(selectedRole===null||selectedRole===undefined){
        selectedRole=options(this.props)
      }
      // console.log("_selectedRole",selectedRole,_options)
      this.setState(prevState => ({
        formRegister: {
          ...prevState.formRegister,
          email: User.email ? User.email : "",
          first: User.first ? User.first : "",
          last: User.last ? User.last : "",
          role: User.role ? selectedRole:""
        }
      }));
    }
   
    
  };

  cancelSubmit = () => {
    this.setState({ view: null })
  }

  onSubmit = e => {
    const form = e.target;
    const inputs = [...form.elements].filter(i =>
      ["INPUT", "SELECT"].includes(i.nodeName)
    );
    this.setState({ view: inputs });
    e.preventDefault();
  };

  OnComplete = value => {
    this.setState({ view: null });
    let payload = {
      ...value,
     role:value.role['name']

    };
    if (this.props.isNewUser) {
      if(this.props.fromCs===false){
      this.props.addUsers({ payload, cb: this.onSuccess });
      }
      else{
        let {id} = this.props;
        
        this.props.addUsers({ payload, cb: this.onSuccess, id:id,fromCs:true})
      }
    }

    else if (
      payload.email !== this.props.User.email ||
      payload.first !== this.props.User.first ||
      payload.last !== this.props.User.last ||(payload.role!==this.props.User.role)
    ) {
      let id = (this.props.fromHeader) ? this.props.id : this.props.User.id,
      accountId = this.props.fromCs === true && this.props.id?this.props.id:"",
       flag = this.props.fromHeader ? true : false,
       fromCs = this.props.fromCs===true?true:false
      if (this.props.fromHeader) { delete payload.role; }
      this.props.updateUser({ payload, id, cb: this.onSuccess, flag,fromCs,accountId });
    }
    this.props.isModalClose('isModal');
  }


  onSuccess = () => {
    if (!this.props.fromHeader) {
      this.props.listUsers();
    }
  };
  onClick = e => {
    this.setState({ role: e.target.value });
  };
  render() {
   
    return (
      <>
        <AddUserStyle>
          <Row>
            <Col lg={12}>
              <form onSubmit={this.onSubmit} name="formRegister">
                <CardBody className="paddingChange">
                  <Col md={12}>
                    <FormContainer
                      fields={this.state.DataModel}
                      submit={this.state.view}
                      cb={this.OnComplete}
                      cb2={this.cancelSubmit}
                      formRegister={this.state.formRegister}
                      colClassName={4}
                    />
                  </Col>
                </CardBody>
                <CardFooter>
               <CardFooterComponent  isNewUser={ this.props.isNewUser} Cancel={()=>this.props.isModalClose('isModal')}/>
                   </CardFooter>
              </form>
            </Col>
          </Row>
        </AddUserStyle>
      </>
    );
  }
}

export default AddUser;
