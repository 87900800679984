import { getLocalStorage,getPersistValue } from "./../../utilities/utilities";


export function onRequest(config) {

  const token = getPersistValue('loginReducer','token','token');

  let Impersonate = JSON.parse(getLocalStorage('impersonate'));
  const AccountId = parseInt(getLocalStorage('accountId'));
  const ImpersonateID = (Impersonate)&&(Impersonate.isImpersonate===true)?parseInt(Impersonate.user_id):null;
  // console.log("_impersoanteId",ImpersonateID)
 
  if (token) {
    config.headers = {
      ...config.headers,
      Authorization: `Bearer ${token}`
    };
  }
  if (AccountId !== null && !isNaN(AccountId)) {
    if(config.headers["Content-Type"] === "application/octet-stream"){
      config.headers = {
        ...config.headers,
        "X-Croptix-AccountID": AccountId,
        "Content-Type": "application/octet-stream" 
      };
    }
    else {
      config.headers = {
        ...config.headers,
        "X-Croptix-AccountID": AccountId,
        'Content-Type':"application/json"
      };
    }
    // console.log("_impersonate",Impersonate)
     if(Impersonate&&(Impersonate.isImpersonate==true)){
      config.headers = {
        ...config.headers,
        "X-Croptix-AccountID": AccountId,
       "X-Croptix-ImpersonateID":ImpersonateID,
       'Content-Type':"application/json"
      };
      // console.log("_inside---impersonate",config)

    }
    
  };
  return config;
}

export function onRequestError(error) {
  return Promise.reject(error);
}



