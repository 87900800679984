export const TOGGLE_HIDDEN = 'TOGGLE_HIDDEN';
export const SET_TABLE_DATA = 'SET_TABLE_DATA';


export const toggleHiddenBlocks = (toggleValue) => ({
    type: TOGGLE_HIDDEN,
    toggleValue
})

export const setTableData = (tableData) => (
    {
    type: SET_TABLE_DATA,
    tableData
})