import styled from "styled-components";

export const LoaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto;
  width: 100%;
  height: 100%;
  background-color:#f5f3f3;
  flex-direction:row-reverse;
  z-index:999999999;
  text-align:center;
  position: fixed;
  top: 0;
  left: 0;
`;
 