import React ,{useState,useEffect} from "react";
import {ToastContainer} from 'react-toastify';
import { Card, Button, CardBody } from "reactstrap";
import { NotificationWrapper } from "./Dashboard.style";
import { formatISODateString } from "./../../utilities/utilities";
import LoaderComponent from "./../../containers/Loader/Loader"; 
import {showToast} from './../config/Toast/Toast';
const Notification = props => {
    const { modalToastValue } = props;
    let [loading,setLoading] = useState(false);
    useEffect(() => {
        props.toastFlag(false);
        return () =>{
            props.toastFlag(true);
            
        }
      }, []);
    
      const deleteNotification =(notification) => {
        setLoading(true)
    let {id} =notification
    props.deleteNotificationById({callback:onNotificationSuccess,id})
    };
    const onNotificationSuccess =(res) => {
        setLoading(false)
    props.getNotificationList()
    }
  return (
    <NotificationWrapper>
      {!props.toastFlagAdd && <ToastContainer />}
          {props.isModalToast && showToast(modalToastValue)}
       {loading? <LoaderComponent width={"60"}  fromModal={true}/>  : <div 
       className={props.notification&&props.notification.length>7?"border":""}
       >
        {props.notification &&
          props.notification.forEach(item => {
            if (!item.has_read) {
              return (
                <Card  key={item.id} className="card-default card">
                  <CardBody className="padding">
                    <div  className="flexDisplay">
                      <div>
                        <div>
                          {formatISODateString(item.created_at)}: {item.subject}
                        </div>
                        <div style={{ fontWeight: "bold" }}>{item.body}</div>
                      </div>
                      <div>
                        {" "}
                        <Button className="bg-croptix-color" onClick={()=>deleteNotification(item)}>Clear</Button>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              );
            }
          })}
      </div>}
      <div className="marginBottom" style={{marginRight:props.notification&&props.notification.length<=7?"15px":"0"}}>
        <Button className="bg-croptix-color" onClick={() => props.modalClose()}>
          Cancel
        </Button>
      </div>
     
    </NotificationWrapper>
  );
};

export default Notification;
