import {
  API,
  NOTIFICATIONS,
  WEATHER_API
} from "./../../components/services/api";
import {
  notificationToast,
  clearValue,
  loading,
  modalToast,
  clearModalToast
} from "./Login";
import axios from "axios";
const config = {
  headers: {
    "Accept": "application/geo+json",
    "Content-Type": "application/geo+json",
    "Access-Control-Allow-Origin": "*"
  }
};
// Function for get samples
export const getNotification = callback => {
  return dispatch => {
    // dispatch(loading(true));
    return API.get(`${NOTIFICATIONS}?limit=${1000}&offset=${0}`, {}, {})
      .then(res => {
        dispatch(setNotification(res.data));
        if (callback) callback(res);
        dispatch(loading(false));
      })
      .catch(err => {
        dispatch(loading(false));

        let { message } = err.response.data;
        dispatch(notificationToast({ type: "error", message: message }));
        dispatch(clearValue());
        return err;
      });
  };
};

// Function for clearing  a particular notification  by Id

export const deleteNotificationById = ({ callback, id }) => {
  return dispatch => {
    // dispatch(loading(true));
    return API.delete(`${NOTIFICATIONS}/${id}`, {}, {})
      .then(res => {
        if (callback) callback(res);
        dispatch(
          modalToast({
            type: "success",
            message: "Notification cleared successfully"
          })
        );
        dispatch(clearModalToast());
        dispatch(loading(false));
      })
      .catch(err => {
        dispatch(loading(false));
        if (callback) callback();
        let { message } = err.response.data;
        dispatch(notificationToast({ type: "error", message: message }));
        dispatch(clearValue());
        return err;
      });
  };
};

// function for getting weather using lat long from
export const getWeatherDetails = ({ location, cb }) => {
  
  let { lat, lng } = location;
  return dispatch => {
    return axios
      .get(`${WEATHER_API}/points/${lat},${lng}`, {}, config
      )
      .then(res => {
        dispatch(loading(false));
        if (res) {
          let forecastURL = res.data.properties.forecast;
          dispatch(getDetailedForecast(forecastURL, cb));
        }
      })
      .catch(err => {
        if (cb) cb([]);
        dispatch(loading(false));
      });
  };
};

// function to get forecast from zoneId

export const getZoneId = (zoneId, cb) => {
  
  return dispatch => {
    // dispatch(loading(true));
    return axios
      .get(`${WEATHER_API}/zones/land/${zoneId}/forecast`, {}, config)
      .then(res => {
        dispatch(loading(false));
        // console.log("_response",res)
        let weather_data = res.data.periods;
        if (cb) cb(weather_data);
      })
      .catch(err => {
        dispatch(loading(false));
        if (err) {
          if (cb) cb([]);
        }

        return err;
      });
  };
};
// function for getting detailed forecast;
export const getDetailedForecast = (forecastURL, cb) => {
 
  return dispatch => {
    return axios
      .get(`${forecastURL}`, {}, config)
      .then(res => {
        if (res) {
          let weather_data = res.data.properties.periods;
          if (cb) cb(weather_data);
        }
        dispatch(loading(false));
      })
      .catch(err => {
        dispatch(loading(false));
        if (err) {
          if (cb) cb([]);
        }

        return err;
      });
  };
};

export const SET_NOTIFICATION = "SET_NOTIFICATION";

export const setNotification = payload => ({
  type: SET_NOTIFICATION,
  notification: payload
});
