import {logOut} from './../../utilities/utilities'
export function onResponse(response) {
  if (response.status === 200) {
    return response.data;
  } else {
    return response.data;
  }
}

export function onResponseError(error) {

  let customError = { ...error };
  let _error = error.response;
  const status = Number(error.response
    ? error.response.status || error.response.status
    : 1);

  if (status === 400) {
    customError = {
      response: {
        data: {
          message: error.response.data.message
        },
        status: 400
      }
    };
    customError= Promise.reject(customError)
  } else if (status === 401) {
    logOut();
    customError = Promise.reject(getUnknownError(_error));;
  } else {
    customError = Promise.reject(getUnknownError(_error));
  };
  console.log("_customError",customError)
  return customError
}
const getUnknownError = response => (
  {
  response: {
    data: {
      message:
      response&&response.data?response.data.message:"Service Unavailable. Please try after some time",
    }
  } 
});
