import { SET_CS_ACCOUNT } from "../actions/CustomerSupportAction";

const initialSettings = {
 accountObj:{}
};

const CustomerSupport = (state = initialSettings, action) => {
  switch (action.type) {
    case SET_CS_ACCOUNT:
      return {
        ...state,
        accountObj:action.accountObj
      };
   
    default:
      return state;
  }
};

export default CustomerSupport;

