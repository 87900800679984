import {
  API,
  GET_CUSTOMER_SUPPORT_ACCOUNT,CS_USERS,SET_UP_GET_ACCOUNT,ADD_ACCOUNT_ADDRESS,TREES,CYRUS_TREES
} from "./../../components/services/api";
import { loading, notificationToast, clearValue,modalToast,clearModalToast,ImpersonateUserInformation } from "./Login";
import axios from "axios";
import { getIdFromUrl, getLocalStorage } from "../../utilities/utilities";


// To get all customer support account
export const getAllCustomerSupportAccount = ({ cb }) => {
  return dispatch => {
    dispatch(loading(true));
    return API.get(`${GET_CUSTOMER_SUPPORT_ACCOUNT}?offset=${0}&limit=${1000}`, {}, {})
      .then(res => {
        dispatch(loading(false));
        // console.log("_res", res);
        if (cb) cb(res);
      })
      .catch(err => {
        dispatch(loading(false));
        console.log("_error", err);
        return err;
      });
  };
};

// for adding  new account
export const addAccount = ({ payload1,payload, cb }) => {
  return dispatch => {
    dispatch(loading(true));
    return API.post(GET_CUSTOMER_SUPPORT_ACCOUNT, payload1, {})
      .then(res => {
        dispatch(loading(false));
        payload.account_id = res.id;
        let _fromEdit = false;
        let id =res.id
        dispatch(addAddress({id,payload,cb,_fromEdit}))
        
      })
      .catch(err => {
        dispatch(loading(false));
        let { message } = err.response.data;
        if (err.response.data.status === 500) {
          dispatch(
            notificationToast({
              type: "error",
              message: "Account Already exists"
            })
          );
          dispatch(clearValue());
        } else {
          dispatch(notificationToast({ type: "error", message: message }));
          dispatch(clearValue());
        }
        console.log("_err", err);
      });
  };
};

// add address after CS -> Account creation
export const addAddress = ({id, payload,cb,_fromEdit}) => {
  return dispatch => {
    dispatch(loading(true));
    return API.post(
      `${GET_CUSTOMER_SUPPORT_ACCOUNT}/${id}/addresses`,
      payload,
      {}
    )
      .then(res => {
        dispatch(loading(false));
        // console.log("_res",res);
        if(cb)cb(res);
        if(!_fromEdit){
        dispatch(notificationToast({ type: "success", message: "Account created Successfully" }));
        dispatch(clearValue());
        }
        else{
          dispatch(notificationToast({ type: "success", message: "Addresses Added Successfully" }));
          dispatch(clearValue());
        }
      })
      .catch(err => {
        dispatch(loading(false));
        let { message } = err.response.data;
        if (err.response.data.status === 500) {
          if(!_fromEdit){
          dispatch(
            notificationToast({
              type: "error",
              message: "Account created Successfully but failed to add address"
            })
          );
          dispatch(clearValue());
          }

        } else {
          dispatch(notificationToast({ type: "error", message: message }));
          dispatch(clearValue());
        }
        console.log("_err", err);
      });
  };
};
// export const getCurrentAccount

export const getCurrentAccount = ({id,cb}) => {
  return dispatch => {
    dispatch(loading(true));
    return API.get(`${GET_CUSTOMER_SUPPORT_ACCOUNT}/${id}`, {}, {})
      .then(res => {
        dispatch(loading(false));
        // console.log("_res", res);
        if (cb) cb(res);
      })
      .catch(err => {
        dispatch(loading(false));
        console.log("_error", err);
        return err;
      });
  };
};
export const SET_CS_ACCOUNT = "SET_CS_ACCOUNT";

export const setAccount = payload => ({
  type: SET_CS_ACCOUNT,
  accountObj: payload
});


// // calling google map api(geocoding)

export const getLatLong = ({ address, cb }) => {
  return dispatch => {
    let maps = new window.google.maps.Geocoder();
    maps.geocode({ address: address }, function(results, status) {
      if (status === "OK") {
        let country = results[0]['address_components']&&results[0]['address_components']
          .filter(item => item.types.includes('country'))[0]
          .long_name,
        
        location = {
          lat: results[0].geometry.location.lat(),
          lng: results[0].geometry.location.lng()
        };
        let obj={country,location}
        if (cb) return cb(obj);
      } else if(status==="ZERO_RESULTS") {
        dispatch(
          modalToast({
            type: "warning",
            message: "Cannot determine address of this location."
          })
        );
        dispatch(clearModalToast());
      }
      else{
        dispatch(
          modalToast({
            type: "warning",
            message: "Google map API unvailable at the moment"
          })
        );
        dispatch(clearModalToast());
      }
    });
  };
};
// google timezone
export const getTimezone = ( {lat,lng,cb}) => {
return dispatch => {
  axios.post(`https://maps.googleapis.com/maps/api/timezone/json?location=${lat},${lng}&timestamp=1458000000&key=${process.env.REACT_APP_GOOGLE_MAP_API_KEY}`)
  .then(res=>{

    let timezone = res.data.timeZoneId
    if(cb)return cb(timezone)
  })
  .catch(err=>console.log("err",err))
}
}

// delete an Account Address

export const deleteAddress = ({account_id,cb,id,fromCs}) => {
  return dispatch => {
    dispatch(loading(true));
    let URL = fromCs===true? `${GET_CUSTOMER_SUPPORT_ACCOUNT}/${account_id}/addresses/${id}`:`${ADD_ACCOUNT_ADDRESS}/${id}`
    return API.delete(
      URL,
      {},
      {}
    )
      .then(res => {
        dispatch(loading(false));
        if(cb)cb(res);
       
        dispatch(notificationToast({ type: "success", message: "Address deleted Successfully" }));
        dispatch(clearValue());
        
      })
      .catch(err => {
        dispatch(loading(false));
        let { message } = err.response.data;
        if (err.response.data.status === 500) {
         
          dispatch(
            notificationToast({
              type: "error",
              message: "Failed to delete Address"
            })
          );
          dispatch(clearValue());
          

        } else {
          dispatch(notificationToast({ type: "error", message: message }));
          dispatch(clearValue());
        }
        console.log("_err", err);
      });
  };
};

// Edit Account 

export const updateAccount = ({id,cb,payload,fromCs}) => {
  return (dispatch) => {
    dispatch(loading(true));
    let URL = fromCs===true?`${GET_CUSTOMER_SUPPORT_ACCOUNT}/${id}`:SET_UP_GET_ACCOUNT
    return API.patch(
      URL,
      payload,
      {}
    )
      .then(res => {
        if(cb)cb(res);
        dispatch(loading(false));
        localStorage.removeItem(`selected_account_${getIdFromUrl()}`);
        dispatch(notificationToast({ type: "success", message: "Account updated Successfully" }));
        dispatch(clearValue());

        
      })
      .catch(err => {
        if(cb)cb();
        dispatch(loading(false));
        let { message } = err.response.data;
        if (err.response.data.status === 500) {
         
          dispatch(
            notificationToast({
              type: "error",
              message: "Failed to update account"
            })
          );
          dispatch(clearValue());
          

        } else {
          dispatch(notificationToast({ type: "error", message: message }));
          dispatch(clearValue());
        }
        console.log("_err", err);
      });
  };
};

// Edit Address in cs/account
export const updateAddress = ({account_id,payload,cb,id,fromCs}) => {
  let URL=fromCs===true? `${GET_CUSTOMER_SUPPORT_ACCOUNT}/${account_id}/addresses/${id}`:`${ADD_ACCOUNT_ADDRESS}/${id}`
  return dispatch => {
    dispatch(loading(true));
    return API.patch(
      URL,
      payload,
      {}
    )
      .then(res => {
        dispatch(loading(false));
        // console.log("_res",res);
        if(cb)cb(res);

        dispatch(notificationToast({ type: "success", message: "Address updated Successfully" }));
        dispatch(clearValue());
       
      })
      .catch(err => {
        dispatch(loading(false));
        // if(cb)cb();
        let { message } = err.response.data;
        if (err.response.data.status === 500) {
          
          dispatch(
            notificationToast({
              type: "error",
              message: "Failed to update Address"
            })
          );
          dispatch(clearValue());

        } else {
          dispatch(notificationToast({ type: "error", message: message }));
          dispatch(clearValue());
        }
        console.log("_err", err);
      });
  };
};

// function to get all users - cs

export const getCsUsers = ({ callback,id }) => {
  return dispatch => {
    dispatch(loading(true));
    return API.get(`${GET_CUSTOMER_SUPPORT_ACCOUNT}/${id}/users`, {}, {})
      .then(res => {
        dispatch(loading(false));
        // console.log("_res", res);
        if (callback) callback(res);
      })
      .catch(err => {
        dispatch(loading(false));
        console.log("_error", err);
        return err;
      });
  };
};

// Function to get particular users permission for impersonation in cyrus
export const getCsUsersPermission = ({ callback,userId,accountId }) => {
  return dispatch => {
    dispatch(loading(true));
    return API.get(`${CS_USERS}/${userId}`, {}, {})
      .then(res => {
        if(res&&res.roles){
          res.roles&&res.roles.map(item=>{
            if(item.account_id === accountId){
              let obj ={
                user_id:userId,
                accountId:accountId,
                masterAccountId:getLocalStorage("accountId"),
                isImpersonate:true,
                permission:item.permissions,
                globalPermission:res.global_permissions?res.global_permissions:""
              };
              dispatch(ImpersonateUserInformation(obj));
            }
          })
        }
        dispatch(loading(false));
        if (callback) callback(res);

        // console.log("_res", res);
      })
      .catch(err => {
        dispatch(loading(false));
        console.log("_error", err);
        return err;
      });
  };
};

// function for addingAddress in account
export const addAddressAccount = ({cb,payload}) => {
  return dispatch => {
    dispatch(loading(true));
    return API.post(
      ADD_ACCOUNT_ADDRESS,
      payload,
      {}
    )
      .then(res => {
        dispatch(loading(false));
        if(cb)cb(res);

        dispatch(notificationToast({ type: "success", message: "Address added Successfully" }));
        dispatch(clearValue());
       
      })
      .catch(err => {
        dispatch(loading(false));
        let { message } = err.response.data;
        if (err.response.data.status === 500) {
          
          dispatch(
            notificationToast({
              type: "error",
              message: "Failed to update Address"
            })
          );
          dispatch(clearValue());

        } else {
          dispatch(notificationToast({ type: "error", message: message }));
          dispatch(clearValue());
        }
      });
  };
}

// Function to add the Individual Trees

export const addTrees = ({callback,payload} ) => {
  return dispatch => {
    dispatch(loading(true));
    return API.post(`${CYRUS_TREES}`, payload, {})
      .then(res => {
        dispatch(loading(false));
        dispatch(
          notificationToast({
            type: "success",
            message: "Tree added successfully"
          })
        );
        dispatch(clearValue());
        if (callback) callback(res);
      })
      .catch(err => {
        dispatch(loading(false));
        if (callback) callback();
        let { message } = err.response.data;
        dispatch(notificationToast({ type: "error", message: message }));
        dispatch(clearValue());
        return err;
      });
  };
};

export const getTrees = ({callback,accountId,block_id,fromCs,fromTask} ) => {
  let URL= fromCs?`${CYRUS_TREES}?account_id=${accountId}&&offset=${0}&limit=${100000}&&block_id=${block_id}`:!fromTask?`${TREES}?offset=${0}&limit=${100000}&&block_id=${block_id}`:`${TREES}?offset${0}&&limit=${100000}`
  return dispatch => {
    (fromCs||fromTask)&& dispatch(loading(true));
    return API.get(URL, {}, {})
      .then(res => {
        dispatch(loading(false));
      if (callback) callback(res);
      })
      .catch(err => {
        dispatch(loading(false));
        if (callback) callback();
        
        return err;
      });
  };
};

// function for removing trees
export const deleteTrees = ({callback,id} ) => {
  return dispatch => {
    dispatch(loading(true));
    return API.delete(`${CYRUS_TREES}/${id}`,{}, {})
      .then(res => {
        dispatch(loading(false));
        dispatch(
          notificationToast({
            type: "success",
            message: "Tree removed successfully"
          })
        );
        dispatch(clearValue());
        if (callback) callback(res);
      })
      .catch(err => {
        dispatch(loading(false));
        if (callback) callback();
        let { message } = err.response.data;
        dispatch(notificationToast({ type: "error", message: message }));
        dispatch(clearValue());
        return err;
      });
  };
};

// function for removing trees bulk 

export const BulkDelete = ({callback,payload,flag}) => {
  return dispatch => {
    dispatch(loading(true));
    return API.delete(`${CYRUS_TREES}/bulk`,{data:payload.items}, {})
      .then(res => {
        dispatch(loading(false));
       {flag&& dispatch(
          notificationToast({
            type: "success",
            message: "Updated successfully"
          })
        );
        dispatch(clearValue());}
        if (callback) callback(res);
      })
      .catch(err => {
        dispatch(loading(false));
        if (callback) callback();
        let { message } = err.response.data;
        dispatch(notificationToast({ type: "error", message: message }));
        dispatch(clearValue());
        return err;
      });
  };
};


// function for add Trees in bulk

export const BulkAdd = ({callback,payload,flag}) => {
  return dispatch => {
    dispatch(loading(true));
    return API.post(`${CYRUS_TREES}/bulk`,payload, {})
      .then(res => {
        dispatch(loading(false));
       {flag&& dispatch(
          notificationToast({
            type: "success",
            message: "Updated successfully"
          })
        );
        dispatch(clearValue());}
        if (callback) callback(res);
      })
      .catch(err => {
        dispatch(loading(false));
        if (callback) callback();
        let { message } = err.response.data;
        dispatch(notificationToast({ type: "error", message: message }));
        dispatch(clearValue());
        return err;
      });
  };
};

// function for bulk edit 

export const BulkEditTrees = ({callback,payload,flag}) => {
  return dispatch => {
    dispatch(loading(true));
    return API.patch(`${CYRUS_TREES}/bulk`,payload, {})
      .then(res => {
        dispatch(loading(false));
       { flag&&dispatch(
          notificationToast({
            type: "success",
            message: "Updated successfully"
          })
        );
        dispatch(clearValue());}
        if (callback) callback(res);
      })
      .catch(err => {
        dispatch(loading(false));
        if (callback) callback();
        let { message } = err.response.data;
        dispatch(notificationToast({ type: "error", message: message }));
        dispatch(clearValue());
        return err;
      });
  };
};
