const Role = {
    ShowDataCollectorRole:{
        label: "Data Collector",
        name: "Data Collector"
    },
    ShowAdminRole:{
        label: "Admin",
        name: "Admin"
    }
};
const allowedRole = [{ label:"Standard", name:"Standard" }]

export const options =(props) => {
    let accountPermission = props.permission
    ? props.permission.split(",")
    : [];
 let _permittedrole = accountPermission
        .map(item => Role[item])
        .filter(item => item);
        
let _role = [...allowedRole,..._permittedrole]
   return _role
}