import { combineReducers } from 'redux';

import settingsReducer from './settings.reducer.js';
import themesReducer from './themes.reducers.js';
import loginReducer from './Login.reducer.js';
import CustomerSupport from './CustomerSupportReducer.js';
import setMenuItems from './setMenuItems';
import BlockReducer from './BlockReducer';
import Blocks from './Blocks';
import Dashboard from './DashboardReducer';

export default combineReducers({
    settings: settingsReducer,
    theme: themesReducer,
    loginReducer:loginReducer,
    CustomerSupport: CustomerSupport,
    BlockReducer:BlockReducer,
    setMenuItems,
    Blocks,
    Dashboard
});
