import { TREE_VARIETY,SET_ACCOUNT_ADDRESS,IS_NAVIGATE_BLOCK } from "../actions/BlockAction";

const initialSettings = {
  treeVariety:[],
  address:{},
  blocks:[]
};

const BlocksReducer = (state = initialSettings, action) => {
  switch (action.type) {
    case TREE_VARIETY:
      return {
        ...state,
        treeVariety:action.treeVariety
      };
    
     
      case SET_ACCOUNT_ADDRESS:
      return{
        ...state,
        address:action.address
      }
      case IS_NAVIGATE_BLOCK:
                return {
                    ...state,
                    isNavigateBlock:action.value
                }
    default:
      return state;
  }
};

export default BlocksReducer;